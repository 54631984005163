//////////////////////////////////////////
// footer
//////////////////////////////////////////
footer {
  padding: 0 8vw;
  background-color: #fff;
  @include media-breakpoint-up(lg) {
    padding: 3px 30px;
  }
  .horizontal-seperator {
    margin: 3px 0;
    height: 2px;
    background-color: rgba(#f39449, 0.2);
  }
  .footer-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    height: 120px;
  }
  .footer-powered-by {
    margin-bottom: 0px;
    margin-right: 10px;
  }

  .footer-inner {
    display: flex;
    flex-wrap: wrap;
    margin-top: 7.467vw;
    margin-bottom: 5.333vw;
    @include media-breakpoint-up(lg) {
      flex-wrap: nowrap;
      margin-top: 28px;
      margin-bottom: 44px;
    }

    .logo-wrapper {
      margin-bottom: 5.333vw;
      flex: 0 0 100%;
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        flex: 0 0 auto;
        margin-right: 70px;
      }

      .rtl & {
        @include media-breakpoint-up(lg) {
          margin-right: unset;
          margin-left: 70px;
        }
      }

      .terrific-logo {
        margin-bottom: 5px;
        @include media-breakpoint-up(lg) {
          width: 80px;
        }
      }

      p {
        margin-bottom: 0;
        font-size: 3.733vw;
        line-height: 5.6vw;
        @include media-breakpoint-up(lg) {
          font-size: 12px;
          line-height: 18px;
        }
      }
    }

    .nav-wrapper {
      display: flex;
      align-items: flex-start;
      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
        flex: 1 1 auto;
      }

      .nav {
        margin-bottom: 2.667vw;
        flex: 1 1 50%;
        @include media-breakpoint-up(lg) {
          margin-bottom: 0;
          flex: 0 0 auto;
          margin-right: 70px;
        }

        .rtl & {
          @include media-breakpoint-up(lg) {
            margin-right: auto;
            margin-left: 70px;
          }
        }

        .title {
          margin-bottom: 0;
          font-size: 3.733vw;
          font-weight: 700;
          line-height: 5.6vw;
          @include media-breakpoint-up(lg) {
            font-size: 12px;
            line-height: 18px;
          }
        }

        .nav-link {
          padding: 0;
          color: #000;
          font-size: 3.733vw;
          line-height: 5.6vw;
          @include media-breakpoint-up(lg) {
            font-size: 12px;
            line-height: 18px;
          }
        }

        &:nth-child(2),
        &:nth-child(4) {
          flex: 1 1 40%;
          @include media-breakpoint-up(lg) {
            flex: 0 0 auto;
          }
        }
      }

      .gppr-link {
        width: 37.067vw;
        @include media-breakpoint-up(lg) {
          width: auto;
          margin-left: auto;
        }

        .rtl & {
          @include media-breakpoint-up(lg) {
            margin-left: unset;
            margin-right: auto;
          }
        }
      }
    }
  }
}
