ngb-datepicker.dropdown-menu {
  border-radius: 10px;
  background-color: #fff;
  border: 0;

  .ngb-dp-header {
    background-color: transparent;

    .ngb-dp-navigation-select {
      .custom-select {
        color: #000;
        font-size: 13px;
        text-transform: uppercase;
        border: 0;
        background-color: transparent;
        background-image: escape-svg(
          url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#000' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>")
        );
        box-shadow: none;
        option {
          color: #000;
        }
      }
    }

    .ngb-dp-arrow {
      .ngb-dp-arrow-btn {
        color: #000;
        font-size: 12px;
        outline: 0;
        box-shadow: none;
        &:disabled,
        &[disabled] {
          background: transparent !important;
        }
      }
    }
  }

  .ngb-dp-content {
    &.ngb-dp-months {
      .ngb-dp-month {
        .ngb-dp-week {
          &.ngb-dp-weekdays {
            background-color: transparent;
            border: 0;
          }

          .ngb-dp-weekday {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            width: 26px;
            font-weight: 500;
            font-size: 10px;
            line-height: 11px;
            color: rgba(#000, 0.5);
            font-style: normal;
          }

          .ngb-dp-day {
            margin: 1px;
            width: 24px;
            height: 24px;
            outline: 0;
            .btn-light {
              display: flex;
              justify-content: center;
              align-items: center;
              color: #000;
              padding: 0;
              font-weight: 500;
              font-size: 10px;
              border-radius: 50%;
              width: 24px;
              height: 24px;
              border: 0px solid #fff;
              outline: 0;

              &:hover {
                color: #000;
              }

              &.btn-primary {
                color: #000;
              }

              &.active {
                background-color: var(--primary-store);
                color: #fff;
              }
            }
          }
        }
      }
    }
  }
}
