.product-customization-modal {
  .modal-dialog {
    margin: 0 10px;
    width: auto;
    @include media-breakpoint-up(md) {
      margin: auto;
      width: 520px;
      max-width: 520px;
    }
    .modal-content {
      border-radius: 15px;
      background: #fff;
      box-shadow: 0px 4px 20px rgba(3, 12, 35, 0.1);

      app-product-customization-modal {
        position: relative;

        .title {
          margin-top: 80px;
          text-align: center;
          font-weight: 700;
          font-size: 25px;
          line-height: 30px;
        }

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 34px;
          text-align: center;
          letter-spacing: -0.03em;
          color: #58575a;
        }

        .customization-form {
          width: 338px;
          margin: 0 auto 40px;

          p {
            margin-top: 20px;
          }

          label {
            display: block;
            font-size: 14px;
          }

          .customization-label-input {
            font-size: 14px;
            font-weight: 500;
            background-color: #f3f3f3;
            &.upto-10 {
              width: 120px;
            }
            &.upto-20 {
              width: 240px;
            }
          }

          .hint-text {
            font-size: 10px;
          }
        }

        .customization-image-form {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          width: 100%;
          .image-upload-container {
            position: relative;
            width: 190px;
            height: 190px;
            background-image: url('/assets/images/file-upload-drop-bg.svg');
            background-position: center center;
            background-size: 85px 105px;
            background-repeat: no-repeat;
            border: 1px solid #dde2eb;
            border-radius: 50%;
            img.customization-image {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
              border-radius: 50%;
              overflow: hidden;
              border: 1px solid #dde2eb;
            }

            ngx-file-drop {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              z-index: 1;

              .btn-upload {
                position: absolute;
                right: 0;
                bottom: 15px;
                width: 38px;
                height: 38px;
                padding: 0;
                background: #ffffff;
                box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.15), 0px 0px 15px rgba(0, 0, 0, 0.05);

                img {
                  width: 18px;
                  height: 18px;
                }
              }
            }
          }

          .desc-text,
          .error-text {
            display: flex;
            align-items: center;
            margin-top: 20px;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 0.03em;
            color: #9e9ea9;
          }
        }

        .btn-back,
        .btn-edit {
          display: flex;
          margin: 4px auto 0;
          width: 75px;
          @include media-breakpoint-up(xl) {
            width: 115px;
          }
        }
      }
    }
  }
}
