.reminder-confirmed-modal {
  .modal-dialog {
    margin: 0 10px;
    width: auto;
    @include media-breakpoint-up(md) {
      margin: auto;
      width: 530px;
    }
    .modal-content {
      border-radius: 15px;
      background-color: #fff;
      app-reminder-confirmed-modal {
        position: relative;
        .checkmark-circle {
          position: absolute;
          top: 0;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100px;
          height: 100px;
          z-index: 1;
          user-select: none;
          @include media-breakpoint-up(xl) {
            transform: translate(-50%, calc(-50% - 20px));
          }
        }

        .title {
          margin-top: 54px;
          text-align: center;
          font-weight: 700;
          font-size: 25px;
          line-height: 30px;
          @include media-breakpoint-up(xl) {
            margin-top: 66px;
          }
        }

        p {
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          text-align: center;
          letter-spacing: -0.03em;
          color: #58575a;
        }

        .btn-back {
          display: flex;
          margin: 24px auto 0;
          width: 280px;
          @include media-breakpoint-up(xl) {
            width: 300px;
            margin: 34px auto 0;
          }
        }
      }
    }
  }
}
