// Variables
@import 'variables';

// Helpers
@import 'helpers';

// Bootstrap
@import 'bootstrap/bootstrap-rtl';

// Swiper
@import 'swiper/css/bundle';

// Fonts
@import 'heebo-font-hebrew-only';

// iconly
@import url('assets/fonts/iconly/css/style.css');

// icomoon
@import url('assets/icomoon/style.css');

//////////////////////////////////////////
// Components
//////////////////////////////////////////
@import 'components/header';
@import 'components/app-loader';
@import 'components/buttons';
@import 'components/dropdown';
@import 'components/user-menu-dropdown';
@import 'components/homepage-product-cards';
@import 'components/session-product-cards';
@import 'components/session-product-cards-mobile';
@import 'components/camera-feed-desktop';
@import 'components/camera-feed-mobile';
@import 'components/chat-desktop';
@import 'components/chat-mobile';
@import 'components/progress-bar-desktop';
@import 'components/progress-bar-mobile';
@import 'components/tabs-desktop';
@import 'components/tabs-mobile';
@import 'components/scrollbar';
@import 'components/forms';
@import 'components/ng-select-custom';
@import 'components/datepicker';
@import 'components/sprite-flags';
@import 'components/tooltip';
@import 'components/popover';
@import 'components/modals';
@import 'components/store-modals';
@import 'components/landscape-warning';
@import 'components/mobile-pull-up-container';
@import 'components/mobile-product-pull-up';
@import 'components/session-entrance-fee';
@import 'components/footer';

@import 'components/cart-mobile';
@import 'components/checkout-mobile';
@import 'components/lottie-like-animation';
@import 'components/lottie-wave-animation';
@import 'components/no-image';
@import 'components/custom-scroll';

//////////////////////////////////////////
// Sessions main content
//////////////////////////////////////////
@import 'components/live-session-content';
@import 'components/live-session-content-mobile';
@import 'components/offline-session-content';
@import 'components/offline-session-content-mobile';
@import 'components/live-session-host';
@import 'components/live-session-host-mobile';

//////////////////////////////////////////
// Screens
//////////////////////////////////////////
@import 'screens/homepage';
@import 'screens/store-homepage';
@import 'screens/session';

//////////////////////////////////////////
// Admin screens
//////////////////////////////////////////
@import 'admin-styles';
@import 'screens/store-users';
@import 'screens/store-settings';
@import 'screens/store-products';
@import 'screens/store-adding-products';
@import 'screens/store-sessions';
@import 'screens/store-adding-session';

//////////////////////////////////////////
// Modals
//////////////////////////////////////////
@import 'modals/login';
@import 'modals/profile-details';
@import 'modals/payment-details';
@import 'modals/entrance-fee';
@import 'modals/orders';
@import 'modals/registered-sessions';
@import 'modals/checkout';
@import 'modals/order-confirmation';
@import 'modals/reminder-confirmed';
@import 'modals/session-review';
@import 'modals/shipping-methods';
@import 'modals/size-table';
@import 'modals/currency-select';
@import 'modals/pause-broadcast';
@import 'modals/product-customization';
@import 'modals/wow-session-link';
@import 'modals/add-new-collection';
@import 'modals/embededd-session-timer';
@import 'modals/toggle-reminder-modal';
@import 'modals/warning-lower-power-mode';
@import 'modals/confirmation-modal';

.firebase-emulator-warning {
  display: none;
}

//////////////////////////////////////////
// HTML
//////////////////////////////////////////
html {
  display: block;
}

//////////////////////////////////////////
// Body
//////////////////////////////////////////
body {
  --default-primary: #{$primary};
  --default-secondary: #{$brand-pink};
  --default-light: #{$light}; // term 'light' might be change while we have a free color choice to something like 'primary-contrast'
  --default-disabled: #{$disabled};

  // because we cant use has()
  --primary-store: var(--default-primary);
  --secondary-store: var(--default-secondary);
  --disabled-store: var(--default-disabled);
  --light-store: var(--default-light);
  --input-Approved: #{$inputApproved};
  display: block;
  padding: 0 env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
  height: calc(var(--vh, 1vh) * 100);

  app-root {
    display: flex;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);

    app-app-layout,
    app-store-manage {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;

      app-session {
        width: 100%;
        height: 100%;
      }

      app-live-session-host {
        width: 100%;
        height: 100%;
      }
    }
  }
}

// :has(), not supported yet
// body:not(:has(app-session, app-store-homepage, app-store-homepage-sessions-only)) {
//   --primary-store: var(--default-primary) !important;
//   --secondary-store: var(--default-secondary) !important;
// }

.line-clamp-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  word-break: break-word;
}

.font-weight-medium {
  font-weight: 600;
}

@each $size, $length in $spacers {
  .text-truncate-#{$size} {
    -webkit-line-clamp: $size;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
  }
}

app-go-to-live-from-demo {
  height: 100%;
}
