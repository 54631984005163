.entrance-fee-message-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 1.528vw 1.528vw;
  border-radius: 1.389vw;
  background-image: url('/assets/images/session-bg-blur.jpg');
  background-size: cover;
  overflow: hidden;

  p {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 330px;
    color: #000000;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;
    letter-spacing: -0.03em;
    text-align: center;
  }

  .pay-fee-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 24px;
    width: 220px;
    height: 32px;
    background: linear-gradient(180deg, #f17a76 0%, #ff928e 100%);
    border-radius: 32px;

    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    color: #ffffff;
  }

  .avatars-contaner {
    position: absolute;
    bottom: calc(var(--vh, 1vh) * 14);
    left: 20%;
    right: 20%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    @include media-breakpoint-up(xl) {
      display: none;
    }

    .brand {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 1 50%;
      .brand-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 52px;
        margin-bottom: 8px;
        width: 52px;
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
        img {
          width: calc(100% - 10px);
          height: calc(100% - 10px);
          object-fit: contain;
          object-position: center;
        }
      }
      span {
        font-weight: 600;
        font-size: 12px;
        text-align: center;
        line-height: 120%;
      }
    }

    .and {
      margin: 0 29px;
      font-weight: 200;
      font-size: 36px;
    }

    .host {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex: 1 1 50%;

      .host-picture {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 0 0 52px;
        margin-bottom: 8px;
        width: 52px;
        border-radius: 50%;
        background-color: #fff;
        overflow: hidden;
        border: 1px solid #01bdfa;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.25);
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      span {
        font-weight: 600;
        font-size: 12px;
        line-height: 120%;
        text-align: center;
      }
    }
  }
}
