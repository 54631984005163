.size-table-modal {
  .modal-dialog {
    margin: 0 10px;
    width: auto;
    @include media-breakpoint-up(md) {
      margin: auto;
    }
    .modal-content {
      position: relative;
      border-radius: 15px;
      background-color: #fff;

      app-size-table-modal {
        .size-table {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 60px;

          .btn-close {
            margin-top: 60px;
            width: 230px;
          }
        }
      }
    }
  }
}
