//////////////////////////////////////////
// Share popover
//////////////////////////////////////////
.popover-share {
  box-shadow: 0px 10px 32px -18px #000000;

  // Sidebar share alignment fix
  &.sidebar-share-popover {
    margin-top: -0.7vw;
  }

  .popover-share-inner {
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(xl) {
      margin: 0 -12.5px;
      flex-direction: row;
    }

    .btn-popover-share {
      margin: 0 0 10px;
      font-size: 11px;
      line-height: 16px;
      @include media-breakpoint-up(xl) {
        margin: 0 12.5px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .icon-holder {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 6px;
        width: 40px;
        height: 40px;
        border-radius: 10px;

        @include media-breakpoint-up(xl) {
          width: 60px;
          height: 60px;
        }

        svg {
          width: 30px;
          height: 30px;
          object-fit: contain;
          object-position: center;
          @include media-breakpoint-up(xl) {
            width: 38px;
            height: 38px;
          }
        }
      }

      span {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }

      &.facebook {
        .icon-holder {
          background-color: #1877f2;
        }
      }
      &.whatsapp {
        .icon-holder {
          background-color: #25d366;
        }
      }
      &.twitter {
        .icon-holder {
          background-color: #000;
        }
      }
      &.mail {
        .icon-holder {
          background-color: #000;
        }
      }
      &.copy-link {
        .icon-holder {
          background-color: #fff;
        }
      }
    }
  }
}
@media (max-width: 1420px) {
  .share-btn-text {
    display: none;
  }
}
.bs-popover-top {
  margin-bottom: calc(#{$popover-arrow-height} + 8px);
}

.bs-popover-right {
  margin-left: calc(#{$popover-arrow-height} + 8px);
  .rtl & {
    margin-left: 0;
    margin-right: calc(#{$popover-arrow-height} + 8px);
  }
}

.bs-popover-bottom {
  margin-top: calc(#{$popover-arrow-height} + 8px);
}

.bs-popover-left {
  margin-right: calc(#{$popover-arrow-height} + 8px);
  .rtl & {
    margin-right: 0;
    margin-left: calc(#{$popover-arrow-height} + 8px);
  }
}

.popover-body {
  @include media-breakpoint-down(lg) {
    padding: 10px;
  }
}
