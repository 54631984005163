//////////////////////////////////////////
// Camera Feed Desktop
//////////////////////////////////////////
.camera-feed-wrapper-desktop {
  position: relative;
  align-self: stretch;
  flex: 0 0 calc(var(--vh, 1vh) * 47.7);
  margin: 24px;
  border-radius: 1.25vw;
  overflow: hidden;

  .camera-feed-placeholder {
    width: auto;
    height: 100%;
  }

  .camera-placeholder-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  .camera-feed {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  .badge-live {
    display: flex;
    align-items: center;
    margin-right: 8px;
    top: 0.694vw;
    left: 0.694vw;
    padding: 0 15px;
    height: 35px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    line-height: 1;
    border-radius: 18px;
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.4);

    .rtl & {
      left: unset;
      right: 0.694vw;
    }

    &::before {
      content: '•';
      margin-right: 10px;
      padding-bottom: 4px;
      color: #ff4b33;
      font-weight: 600;
      font-size: 32px;
      .rtl & {
        margin-right: 10px;
        margin-left: unset;
      }
    }
  }

  .btn-mute {
    top: 0.694vw;
    left: 120px;
    z-index: 3;
    .rtl & {
      right: unset;
      right: 120px;
    }

    img {
      width: 35px;
      height: 35px;
    }
  }

  .list-view-item {
    position: absolute;
    top: 0.694vw;
    right: 0.694vw;
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    .btn-view-item {
      position: relative;
      top: unset;
      right: unset;
      cursor: pointer;
      text-align: center;
    }
  }

  .btn-view-item {
    position: absolute;
    top: 0.694vw;
    right: 0.694vw;
    width: 4.514vw;
    color: #fff;
    font-weight: 600;
    font-size: 0.73vw;
    line-height: 1.25vw;
    letter-spacing: 0.02em;
    border-radius: 0.417vw;
    background-color: rgba(#000, 0.4);
    z-index: 1;
    overflow: hidden;

    .rtl & {
      right: unset;
      left: 0.694vw;
    }

    img {
      width: 100%;
      height: 4.514vw;
      border-radius: calc(0.417vw - 3px);
      object-fit: cover;
      object-position: center;
    }
    .remove-view-item {
      position: absolute;
      right: 2px;
      top: 2px;
    }
  }

  .host-buttons {
    position: absolute;
    bottom: 0.694vw;
    left: 0.694vw;
    display: flex;
    z-index: 1;
    overflow: hidden;
    .rtl & {
      left: unset;
      right: 0.694vw;
    }

    .btn-end-session {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 500;
      font-size: 1vw;
      text-transform: uppercase;
      border-radius: 1.215vw;
      z-index: 1;

      .rtl & {
        margin-right: unset;
        margin-left: 0.694vw;
      }
      svg {
        margin-right: 0.694vw;
        width: 1vw;
        height: 1vw;
        .rtl & {
          margin-right: unset;
          margin-left: 0.694vw;
        }
      }
    }

    .btn-start-broadcast {
      padding: 0 10px;

      svg {
        width: 36px;
        height: 36px;
      }
    }

    .btn-stop-broadcast {
      padding: 0 10px;
      svg {
        width: 36px;
        height: 36px;
      }
    }

    .btn-share-screen {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 0.694vw;
      padding: 0 1.042vw;
      width: 12.361vw;
      height: 2.431vw;
      color: #fff;
      font-weight: 500;
      font-size: 1vw;
      text-transform: uppercase;
      border-radius: 1.215vw;
      z-index: 1;
      background-color: var(--primary-store);
      .rtl & {
        margin-right: unset;
        margin-left: 0.694vw;
      }
      svg {
        margin-right: 0.694vw;
        width: 1vw;
        height: 1vw;
        .rtl & {
          margin-right: unset;
          margin-left: 0.694vw;
        }
      }

      .btn-stop-share-screen {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 0.694vw;
        padding: 0 1.042vw;
        width: 12.361vw;
        height: 2.431vw;
        color: #fff;
        font-weight: 500;
        font-size: 1vw;
        text-transform: uppercase;
        border-radius: 1.215vw;
        z-index: 1;
        background-color: var(--primary-store);
        .rtl & {
          margin-right: unset;
          margin-left: 0.694vw;
        }
        svg {
          margin-right: 0.694vw;
          width: 1vw;
          height: 1vw;
          .rtl & {
            margin-right: unset;
            margin-left: 0.694vw;
          }
        }
      }
    }
  }
}
