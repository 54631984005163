//////////////////////////////////////////
// imports
//////////////////////////////////////////

@import '~@ng-select/ng-select/themes/default.theme.css';

//////////////////////////////////////////
// Main layout
//////////////////////////////////////////
:root {
  --color-text-active: #1b2035;
  --color-side-menu: #1a1828;
}
.store-manage-layout {
  display: flex;
  width: 100%;
  padding: 60px 15px 0 0px;
  font-size: 14px;
  color: $body-color;
  font-weight: 500;
  flex: 1 1 auto;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: #58575a;
  }

  .side-menu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 78px;
    width: 100%;
    max-width: 250px;
    background-color: var(--color-side-menu);
    padding: 18px 16px 44px 28px;
    height: 100%;

    .rtl & {
      margin-right: unset;
      margin-left: 40px;
    }

    ul {
      all: unset;
      list-style: none;
      li {
        border-radius: 5px;
        transition: 0.2s;
        &:hover {
          font-weight: 900;
        }
        &.active {
          font-weight: 900;
          background-color: var(--color-text-active);
        }

        a,
        .active {
          padding: 10px 16px;
          display: block;
          color: #ffff;
          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }

  .content-container {
    width: 100%;
    max-width: 65vw;
    margin-left: 28%;
    .rtl & {
      margin-right: 28%;
    }
    flex: 1 1 auto;
  }

  //////////////////////////////////////////
  // Buttons
  //////////////////////////////////////////
  .back-btn {
    margin-bottom: 10px;
    color: #5e6978;
    &:hover {
      text-decoration: none;
      color: #5e6978;
    }
  }

  .button:disabled,
  button[disabled] {
    background: #cccccc !important;
    color: #666666 !important;
    border: none !important;
  }
}

//////////////////////////////////////////
// Buttons
//////////////////////////////////////////
.users-btn {
  width: 100%;
  max-width: 170px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  padding: 7px 22px;
  margin-right: 11px;

  .rtl & {
    margin-right: unset;
    margin-left: 11px;
  }
}

.invitation-send {
  background-color: #e0e0e0;
}

.remove-user {
  color: #ff6c4b;
  background-color: #ffe2db;
}

.custom-admin-btn {
  padding: 12px;
  color: #fff;
  background-color: var(--primary-store);
  border-radius: 5px;
  transition: 0.4s;
  font-size: 18px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  &.green {
    margin-top: 56px;
    background-color: #3bd984;
    &:hover {
      background-color: #33c075;
    }
  }

  &.blue {
    background-color: #01befa;
    &:hover {
      background-color: #01befa;
    }
  }
}

//////////////////////////////////////////
// Colors
//////////////////////////////////////////

.grey {
  color: #b0b5bf;
}

.red {
  color: #ff6c4b;
}

//////////////////////////////////////////
// Reset
//////////////////////////////////////////

img {
  max-width: 100%;
}

//////////////////////////////////////////
// Tabs
//////////////////////////////////////////

// Reset ng tabs
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  background-color: transparent;
  border: none;
  margin-bottom: 0;
}
.nav-tabs .nav-link:hover,
.nav-tabs .nav-link:focus {
  background-color: transparent;
  border: none;
  margin-bottom: 0;
}
.nav-tabs .nav-link {
  border: none;
}

.nav-tabs {
  background-color: transparent;
  border: none;
  margin-bottom: 0;
  &.nav-link {
    border: none;
  }
  &.nav-link.active {
    background-color: transparent;
    border: none;
  }
  a {
    margin-right: 23px;
    margin-bottom: 0;
    padding: 0;
    color: #000;
    font-weight: 500;
    font-size: 14px;

    .rtl & {
      margin-right: unset;
      margin-left: 32px;
    }

    &:hover {
      text-decoration: none;
      margin-bottom: 0;
    }
  }
  .active {
    position: relative;
    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: -3px;
      height: 3px;
      width: 100%;
      background-color: #000;
    }
  }
}

.mt-nav-content {
  margin-top: 45px;
}
.age-restriction {
  display: flex;
  justify-content: space-between;
  .edit-popUp {
    width: 30rem;
    display: flex;
    flex-direction: column;
    .checkBox-input {
      width: 100%;
      position: relative;
      display: flex;
      align-items: center;

      .check-box {
        left: -36px;
        position: absolute;
        width: 40px;
        height: 40px;
      }
    }
  }
}

//////////////////////////////////////////
// Big box tabs
//////////////////////////////////////////
.big-box-tabs {
  display: flex;

  .big-box-button {
    flex: 0 0 300px;
    display: flex;
    margin-right: 20px;
    padding: 26px;
    border: 3px solid transparent;
    background-color: #f8f8f8;
    border-radius: 10px;

    .rtl & {
      margin-right: unset;
      margin-left: 20px;
    }

    &.active {
      border: 3px solid var(--primary-store);

      .icon {
        svg {
          path.path-stroke {
            stroke: var(--primary-store);
          }
          path.path-fill {
            fill: var(--primary-store);
          }
        }
      }
    }

    .icon {
      display: flex;
      margin-right: 26px;
      width: 33px;
      .rtl & {
        margin-right: unset;
        margin-left: 26px;
      }
    }

    .details {
      width: 200px;
      color: #000;
      .title {
        font-size: 16px;
        font-weight: 500;
      }
      .description {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
}

//////////////////////////////////////////
// Tables
//////////////////////////////////////////
.variants-table {
  display: flex;
  flex-direction: column;
  margin-top: 45px;
  max-height: 400px;
  overflow-y: auto;
  .variants-header {
    font-size: 12px;
    color: #aeaeae;
    font-weight: 500;
    margin-bottom: 10px;
    align-items: start;
  }
  .table-height {
    max-height: 360px;
  }

  .variants-row {
    display: flex;
    padding: 15px 0 15px;

    .variants-col {
      display: flex;
      align-items: center;
      .product-input {
        padding: 0 5px 0 14px;

        .rtl & {
          padding: 0 14px 0 5px;
        }
      }

      &:nth-child(1) {
        flex: 0.195;
        display: flex;
        img {
          width: 56px;
          height: 59px;
          border-radius: 10px;
          margin-right: 15px;
          .rtl & {
            margin-right: unset;
            margin-left: 15px;
          }
        }
      }
      &:nth-child(2) {
        flex: 0.09;
        margin-left: 10px;
        display: flex;
        justify-content: center;
        .rtl & {
          margin-left: unset;
          margin-right: 10px;
        }
      }
      &:nth-child(3) {
        flex: 0.135;
        margin-left: 35px;
        .rtl & {
          margin-left: unset;
          margin-right: 35px;
        }
      }
      &:nth-child(4) {
        flex: 0.1;
        margin-left: 25px;
        .rtl & {
          margin-left: unset;
          margin-right: 25px;
        }
      }
      &:nth-child(5) {
        flex: 0.13;
        margin-left: 25px;
        .rtl & {
          margin-left: unset;
          margin-right: 25px;
        }
      }
      &:nth-child(6) {
        flex: 0.1;
        margin-left: 15px;
        .rtl & {
          margin-left: unset;
          margin-right: 15px;
        }
      }
      &:nth-child(7) {
        flex: 0.1;
        margin-left: 15px;
        .rtl & {
          margin-left: unset;
          margin-right: 15px;
        }
      }
      &:nth-child(8) {
        flex: 0.1;
        margin-left: 15px;
        .rtl & {
          margin-left: unset;
          margin-right: 15px;
        }
      }
      &:nth-child(9) {
        flex: 0.1;
        margin-left: 30px;
        .rtl & {
          margin-left: unset;
          margin-right: 30px;
        }
      }
      &:nth-child(10) {
        flex: 0.05;
        margin-left: 15px;
        .rtl & {
          margin-left: unset;
          margin-right: 15px;
        }
      }

      .table-button {
        margin-left: 2px;
        padding: 2px 9px;
        color: #5e6978;
        font-size: 12px;
        line-height: 18px;
        background-color: #dde2eb;
        border-radius: 10px;
        .rtl & {
          margin-left: 0;
          margin-right: 2px;
        }
      }

      .delete-btn {
        width: 22px;
        height: 22px;
        background-image: url(../assets/images/icons/uncheck-img.svg);
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }

  .exclude {
    border-radius: 3px;
    background-color: #b5b9c6;
    .delete-btn {
      background-image: url(../assets/images/icons/left.svg) !important;
      background-color: #dde2eb;
      border-radius: 10px;
    }
  }
}
.age-restriction-checkbox[type='checkbox'] {
  height: 0;
  width: 0;
  visibility: hidden;
}
.age-restriction-label {
  margin-left: 60px;
  margin-right: 16px;
  cursor: pointer;
  text-indent: -9999px;
  width: 46px;
  height: 28px;
  background: rgb(188, 188, 188);
  display: block;
  border-radius: 100px;
  position: relative;
}
.age-restriction-label:after {
  content: '';
  position: absolute;
  top: 5px;
  left: 5px;
  width: 18px;
  height: 18px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
  box-shadow: 0px 4.26724px 3.87931px -2.32759px rgba(14, 14, 44, 0.2),
    inset 0px -0.775862px 0px rgba(14, 14, 44, 0.4);
}

.age-restriction-checkbox:checked + .age-restriction-label {
  background: var(--input-Approved);
}

.age-restriction-checkbox:checked + .age-restriction-label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}
//////////////////////////////////////////
// Inputs
//////////////////////////////////////////
.custom-input-icon-right {
  margin-right: 50px;
  position: relative;
  width: 195%;
  max-width: 140px;
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  border: 0;
  outline: 0;

  input {
    padding: 0 25px 0 14px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    outline: 0;
    font-size: 16px;
    font-weight: 500;

    .rtl & {
      padding: 0 14px 0 25px;
    }
  }

  img,
  .suffix {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    .rtl & {
      right: unset;
      left: 10px;
    }
  }

  .suffix {
    justify-content: center;
    align-items: center;
  }

  input.ng-invalid.ng-touched + img {
    right: 36px;

    .rtl & {
      right: unset;
      left: 36px;
    }
  }

  &::placeholder {
    color: #949ba5;
  }

  &.custom-input-lg {
    height: 50px;
    padding: 0 16px;
    font-size: 16px;
  }

  &.custom-input-sm {
    height: 35px;
    input {
      height: 35px;
    }
  }
}

.custom-viewers-input {
  position: relative;
  width: 100%;
  max-width: 110px;
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  border: 0;
  outline: 0;

  input {
    padding: 0 8px 0 14px;
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    outline: 0;
    font-size: 16px;
    font-weight: 500;

    .rtl & {
      padding: 0 14px 0 8px;
    }
  }

  &::placeholder {
    color: #949ba5;
  }

  &.custom-input-lg {
    height: 50px;
    padding: 0 16px;
    font-size: 16px;
  }

  &.custom-input-sm {
    height: 35px;

    input {
      height: 35px;
    }
  }
}

.checkbox-input {
  input {
    display: none;
  }

  input:checked + label::before {
    background-image: url(../assets/images/icons/checked_blue.svg);
    background-repeat: no-repeat;
    background-position: center center;
  }

  label {
    cursor: pointer;
    color: #28374b;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.3;
    margin: 0;
    padding: 0 0 0 45px;
    position: relative;
    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      width: 35px;
      height: 35px;
      border-radius: 5px;
      background-color: #fff;
      border: 1px solid #c4c4c4;
    }
    .rtl & {
      padding: 0 45px 0 0;
      &::before {
        left: unset;
        right: 0;
      }
    }
  }
}

//////////////////////////////////////////
// Tooltop
//////////////////////////////////////////
.tooltip-btn {
  img {
    min-width: 16px;
    min-height: 16px;
  }
}
.tooltip-inner {
  max-width: 78vw;
  color: #000;
  background: #ffffff;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
  border-radius: 1px;
}

//////////////////////////////////////////
// Store add modal
//////////////////////////////////////////
.store-add-product-modal {
  .modal-dialog {
    max-width: 850px;
    width: 100%;
    .modal-content {
      padding: 50px 35px 50px 50px;
      background: #ffffff;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
        0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
      border-radius: 10px;

      .rtl & {
        padding: 50px 50px 50px 35px;
      }

      .add-modal-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .add-modal-body {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .product-description {
          width: 100%;
          font-size: 16px;
          .title {
            font-size: 12px;
            font-weight: 900;
            color: #b3b3b3;
          }
        }
        .product-images {
          display: flex;
          flex-wrap: wrap;
          width: 100%;
          .active {
            border: 3px solid #01bdfa;
          }
          .selectable-img {
            margin: 0 25px 10px 0;
            border-radius: 10px;
            .rtl & {
              margin: 0 0 10px 25px;
            }

            img {
              border-radius: 10px;
              width: 100px;
              height: 90px;
            }
          }
        }
      }
    }
  }
}

.store-image-preview-modal {
  .modal-dialog {
    max-width: 850px;
    width: 100%;
    .modal-content {
      padding: 50px 35px 50px 50px;
      background: #ffffff;
      box-shadow: 0px 100px 80px rgba(0, 0, 0, 0.07),
        0px 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
        0px 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
        0px 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
        0px 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
        0px 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
      border-radius: 10px;

      .rtl & {
        padding: 50px 50px 50px 35px;
      }

      .preview-modal-header {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      .preview {
        .swiper-slide {
          height: 550px;
          img {
            object-fit: cover;
            object-position: center;
          }
        }
      }
      .thumbs {
        .swiper-slide {
          max-width: 100px;
          border-radius: 10px;
          border: 2px solid transparent;
          img {
            border-radius: 10px;
          }
        }
        .swiper-slide-thumb-active {
          border: 2px solid #dde2eb;
        }
      }
    }
  }
}
.viewers {
  margin-bottom: 14px;
  width: 78px;
  margin-right: 26px;
  max-width: none;
}
.discount {
  margin-left: 22px;
  width: 48px;
}
.coupon-code {
  margin-left: 92px;
  width: 137px;
  .custom-input-icon-right {
    max-width: none;
  }
}
.item {
  width: 313px;
  margin-left: 189px;
  .custom-viewers-input {
    max-width: none;
  }
}
.i-col-viewers {
  margin-right: 25px;
  width: 110px;
  font-size: 16px;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.1882352941);
}
.i-col-discount {
  margin-right: 27px;
  width: 110px;
  font-size: 16px;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.1882352941);
}
.i-col-coupon-code {
  margin-right: 220px;
  width: 116px;
  font-size: 16px;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.1882352941);
}
.i-col-item {
  width: 110px;
  margin-right: 26px;
  font-size: 16px;
  font-weight: 900;
  color: rgba(0, 0, 0, 0.1882352941);
}

.reminder-options {
  height: 6rem;
}

.reminder-option {
  line-height: 2rem;
  margin: 0.5rem 0;
}
