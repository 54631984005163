.store-sessions {
  .create-session-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .headline {
      h2 {
        font-weight: 600;
        font-size: 34px;
      }
      h3 {
        font-weight: 500;
        font-size: 14px;
      }
    }
  }
  .custom-admin-btn {
    max-height: 53px;
  }
  .sessions-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .session {
      margin-top: 25px;
      width: 300px;
      background-color: #f6f6f6;
      height: 460px;
      border-radius: 10px;
      padding: 25px;
      display: flex;
      flex-direction: column;
      .session-preview {
        border-radius: 10px;
        height: 250px;
        width: 100%;
        margin-bottom: 10px;
        object-fit: cover;
        object-position: center top;
      }
      .speaker-info {
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        img {
          border-radius: 50%;
          width: 31px;
          height: 31px;
          margin-right: 10px;
          .rtl & {
            margin-right: unset;
            margin-left: 10px;
          }
        }

        .host-name {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 208px;
        }
      }
      .products-title {
        font-size: 12px;
      }
      .product-imgs {
        display: flex;
        justify-content: space-between;
        margin-bottom: 15px;
        img,
        .more-products {
          width: 56px;
          height: 59px;
          border-radius: 10px;
        }
        .more-products {
          border: 1px dashed #b0b5bf;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          font-size: 11px;
          font-weight: 400;
        }
      }
      .datetime {
        display: flex;
        font-size: 16px;
        time {
          padding-left: 15px;
          .rtl & {
            padding-left: 0;
            padding-right: 15px;
          }
        }
      }
      .custom-admin-btn {
        margin: 20px 0 10px;
        max-height: 45px;
      }

      .buttons {
        display: flex;

        .btn-session-edit {
          flex-grow: 1;
        }

        .actions-dropdown {
          flex: 0 0 45px;
          margin-left: 5px;
          .rtl & {
            margin-left: 0;
            margin-right: 5px;
          }

          .more-actions {
            padding: 0;
            width: 100%;
            justify-content: center;
            background-image: none;
            color: #000;
            border-radius: 5px;

            &::after {
              display: none;
            }
            .iconlyBulk-More-Square {
              font-size: 40px;
              .path2:before {
                margin-left: 0;
                .rtl & {
                  margin-left: unset;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }
    .empty-session {
      margin-top: 25px;
      width: 300px;
      height: 460px;
      border-radius: 10px;
      background-image: url(../../assets/images/icons/empty-session.svg);
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #fff;
      border: 1px dashed #b0b5bf;
    }
  }
}
