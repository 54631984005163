.shipping-methods-modal {
  .modal-dialog {
    margin: 0 10px;
    width: auto;
    @include media-breakpoint-up(md) {
      margin: auto;
      max-width: 550px;
    }
    .modal-content {
      position: relative;
      border-radius: 15px;
      background-color: #fff;

      app-shipping-methods-modal {
        .shipping-methods {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 60px;
          .shipping-method {
            margin: 0;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 27px;
            letter-spacing: -0.02em;
            color: #28374b;
            @include media-breakpoint-up(md) {
              margin: 0 20px;
              font-size: 18px;
              width: 420px;
            }
          }

          .seperator {
            margin: 20px 0;
            height: 1px;
            background-color: rgba(#000, 0.2);
            @include media-breakpoint-up(md) {
              width: 460px;
            }
          }

          .btn-close {
            margin-top: 60px;
            width: 230px;
          }
        }
      }
    }
  }
}
