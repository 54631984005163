//////////////////////////////////////////
// Prograss bar desktop
//////////////////////////////////////////
.progress-bar-desktop {
  position: relative;
  flex: 0 0 4.417vw;
  background-color: #e1e1e1;
  justify-self: flex-end;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  margin: 24px 24px 24px 0;
  .rtl & {
    margin: 24px 0 24px 24px !important;
  }

  .goal-discount-product {
    position: absolute;
    top: 3.9vw;
    z-index: 2;
    margin: 0 0.75vw;
    padding: 0;
    width: 4vw;
    height: 4.5vw;
    border: 0.208vw solid #f6f7fb;
    border-top-width: 0.7vw;
    border-radius: 0.347vw;
    overflow: hidden;
    background-color: #f6f7fb;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      border: 0;
      border-radius: 0.347vw;
    }

    &.unavailable {
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        content: '';
        background-image: url('/assets/images/lock.svg');
        background-position: center;
        background-repeat: no-repeat;
        pointer-events: none;
        background-size: 28%;
        z-index: 1;
      }
    }
  }

  .progress-bar-fill {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    min-height: 3vw;
    max-height: calc(100% - 6vw);
    background: linear-gradient(180deg, var(--secondary-store) 0%, var(--primary-store) 100%);
    transition: height 2000ms ease-out;

    // When we have a coupon at the bottom
    &.coupon-reached {
      min-height: 7vw;
    }

    // When reached 100% precent
    &.max-reached {
      max-height: none;

      .fill-wave {
        display: none;
      }

      // lower the logo with text
      .floater {
        transform: translate(0, 6vw);
      }
    }
    // When under 100% precent with space reserved for .goal-discount-product
    &.with-item-discount {
      max-height: calc(100% - 10vw);
      &.coupon-reached {
        min-height: 10.5vw;
      }
      &.max-reached {
        max-height: none;
        .floater {
          transform: translate(0, 10vw);
        }
      }
    }

    &.is-general-discount {
      height: 100%;
      min-height: 0;
      max-height: 100%;
      .floater {
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    .fill-wave {
      position: absolute;
      right: 0;
      width: 100%;
      top: -2vw;
    }

    .floater {
      position: absolute;
      top: 0.264vw;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5vw;
        height: 2.5vw;
        background: #ffffff;
        box-shadow: 0 0.556vw 0.694vw rgba(184, 107, 249, 0.2);
        border-radius: 50%;

        img {
          width: 1vw;
        }
      }

      .value {
        font-weight: 700;
        font-size: 1vw;
        line-height: 1;
        color: var(--secondary-store);
      }

      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.111vw;
        line-height: 1.667vw;
        color: #ffffff;

        .views-icon {
          margin-right: 0.347vw;
          width: 1.111vw;
          .rtl & {
            margin-right: unset;
            margin-left: 0.347vw;
          }
        }
      }
    }
  }

  .progress-bar-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 1.042vw 0;

    .value {
      font-size: 1.042vw;
      font-weight: 700;
      background: linear-gradient(180deg, var(--secondary-store) 0%, var(--primary-store) 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    .goal-discount-product {
      position: relative;
      margin-top: 0.2vw;
      height: 4vw;
      border-top-width: 0.208vw;
      top: 0;
    }
  }

  .canvas-confetti {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
  }
}
