.landscape-warning {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient(220deg, rgba(213, 245, 255, 1) 0%, rgba(1, 189, 250, 1) 69.07%);
  z-index: 1000;

  @media only screen and (hover: none) and (pointer: coarse) and (min-aspect-ratio: 3/2) {
    //(orientation : landscape)
    display: flex;
  }

  .inner-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-grow: 1;
    margin: 0 11.055vw;
    padding: calc(var(--vh, 1vh) * 6.7);
    border: 5px solid #ffffff;
    border-radius: 3.936vw;
    img {
      width: 23.618vw;
      mix-blend-mode: multiply;
      animation: spin 2s ease-out infinite;

      opacity: 0;
      @keyframes spin {
        0% {
          opacity: 0;
          transform: rotate(41deg);
        }
        30% {
          opacity: 1;
          transform: rotate(41deg);
        }
        90% {
          opacity: 1;
          transform: rotate(131deg);
        }
        100% {
          opacity: 0;
          transform: rotate(131deg);
        }
      }
    }

    .text {
      text-align: center;
      color: #fff;
      line-height: 3.099vw;
      .small-text {
        font-weight: 300;
        font-size: 2.094vw;
        line-height: 1;
      }
      .big-text {
        font-size: 3.518vw;
        font-weight: 600;
      }
    }
  }
}
