.offline-session-content-mobile {
  position: relative;
  // TODO: When the pastSessionFlag is false:
  // padding: 40px 28px 60px;
  // TODO: When the pastSessionFlag is true:
  width: 100%;
  background-color: #ffffff;

  //////////////////////////////////////////
  // Session details
  //////////////////////////////////////////
  .session-details-upcoming {
    padding: 18px 24px 10px;
  }
  .back-btn {
    margin-top: 12px;
    margin-left: 16px;
  }
  .session-details {
    overflow-x: clip;
    .back-discount-coupon {
      box-shadow: 0px 14px 23px rgba(28, 37, 44, 0.08);
      position: fixed;
      width: 100%;
      background: white;
      height: 44px;
      z-index: 999;
      padding: 6px;
      padding-left: 12px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .discount {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        .value {
          border: solid;
          padding: 8px 4px 8px 4px;
          border-radius: 12%;
          color: var(--secondary-store);
          font-weight: 600;
          font-size: 15px;
          line-height: 1;
        }
        .discount-text {
          font-size: 15px;
          font-weight: 500;
        }
        .discount-text {
          margin-left: 10px;
          margin-bottom: 0px;
          width: 67%;
          height: 30px;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          display: flex;
          align-items: center;
          letter-spacing: 0.02em;
          &.not {
            font-size: 12px;
            line-height: 15px;
          }
        }
      }
    }

    .organizer-picture {
      .organizer-picture-upcoming-session {
        width: 100%;
        max-height: 220px;
        height: auto;
        aspect-ratio: 1;
        border-radius: 15px;
        overflow: hidden;
      }
      position: relative;
      top: 0px;
      display: flex;
      width: 100%;
      height: auto;
      border-radius: 0px;
      overflow: hidden;
      justify-content: flex-end;
      .top-video-container {
        position: absolute;
        padding: 12px;
        top: 12px;
        width: 100%;
        display: flex;
        height: 42.4px;
        flex-direction: row;
        justify-content: space-between;
        z-index: 1;
        align-items: center;

        .time-and-date {
          padding: 10px;
          color: #ebebeb;
          display: flex;
          align-items: center;
          // margin-bottom: 20px;
          line-height: 1;
          justify-content: flex-end;
          .icon-Time-Circle {
            margin-right: 6px;
          }
          .ended-session-time {
            display: flex;
            font-size: 14px;
            line-height: 20px;
            flex-direction: column;
          }
          .icon-end-session {
            display: flex;
            justify-content: flex-end;
          }
          i {
            font-size: 20px;

            .rtl & {
              margin-right: unset;
              margin-left: 12px;
            }
          }

          .text-bold {
            font-weight: 700;
          }
        }
      }
      .bottom-video-container {
        position: absolute;
        justify-content: space-between;
        display: flex;
        width: 100%;
        height: 42px;
        top: 70%;
        flex-direction: row;
      }
      img,
      app-video-player {
        border-radius: 15px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center top;
      }
    }

    .collaboration {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-bottom: 20px;
      margin-top: 20px;
      .brand {
        display: flex;
        align-items: center;
        flex: 1 1 50%;
        .brand-logo {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 10px;
          flex: 0 0 52px;
          height: 52px;
          border-radius: 50%;
          background-color: #fff;
          overflow: hidden;

          .rtl & {
            margin-right: unset;
            margin-left: 10px;
          }

          img {
            width: calc(100% - 10px);
            height: calc(100% - 10px);
            object-fit: contain;
            object-position: center;
          }
        }
        span {
          font-weight: 600;
          font-size: 12px;
          text-align: center;
          line-height: 120%;
          @extend .line-clamp-2;
        }
      }

      .and {
        margin: 0 29px;
        font-weight: 200;
        font-size: 36px;
      }

      .host {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        flex: 1 1 50%;

        .host-picture {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          flex: 0 0 52px;
          height: 52px;
          border-radius: 50%;
          background-color: #fff;
          overflow: hidden;
          border: 1px solid #01bdfa;

          .rtl & {
            margin-left: unset;
            margin-right: 10px;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        span {
          font-weight: 600;
          font-size: 12px;
          line-height: 120%;
          text-align: center;
        }
      }
    }

    .title {
      margin-bottom: 16px;
      font-size: 18px;
      font-weight: 600;
      line-height: 120%;
    }

    .btn-remind-me {
      margin-bottom: 20px;
      width: 100%;
      &.active {
        background: #b0b5bf;
      }
    }

    .description-text {
      word-wrap: break-word;
      font-size: 14px;
      line-height: 17px;
      white-space: pre-line;
    }

    .buttons {
      left: 0;
      margin-bottom: 20px;
      display: flex;
      justify-content: space-evenly;
      button {
        flex-grow: 1;
        &.btn-like {
          margin-right: 15px;

          .rtl & {
            margin-right: unset;
            margin-left: 15px;
          }

          &.liked {
            color: #fff;
            background-image: linear-gradient(
              180deg,
              var(--secondary-store) 0%,
              var(--primary-store) 100%
            );
          }
        }
      }
    }

    .seperator {
      margin: 0 0 20px;
      width: 100%;
      height: 1px;
      background-color: #dde2eb;
    }

    .discount-details {
      gap: 8px;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      font-weight: 500;
      font-size: 15px;

      .discount-coupon {
        margin-right: 10px;
        margin-left: 10px;

        .rtl & {
          margin-right: unset;
          margin-left: 15px;
        }

        .value {
          border: solid;
          padding: 8px 4px 8px 4px;
          border-radius: 12%;
          color: var(--secondary-store);
          font-weight: 600;
          font-size: 15px;
          line-height: 1;
        }
      }
    }
  }

  //////////////////////////////////////////
  // Session products
  //////////////////////////////////////////
  .session-products {
    margin: 0;
    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 20px;
      color: #5e6978;
      font-size: 18px;
      font-weight: 500;
      i {
        font-size: 22px;
        margin-right: 16px;

        .rtl & {
          margin-right: unset;
          margin-left: 16px;
        }
      }
    }
  }

  //////////////////////////////////////////
  // Prograss bar
  //////////////////////////////////////////
  .progress-bar {
    position: relative;
    flex: 0 0 5.417vw;
    background-color: #696267;
    justify-self: flex-end;

    &.full {
      background: linear-gradient(180deg, var(--secondary-store) 0%, var(--primary-store) 100%);
    }

    .terrific-icon {
      position: absolute;
      top: 1.111vw;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.125vw;
      height: 3.125vw;
      background: #ffffff;
      box-shadow: 0 0.556vw 0.694vw rgba(184, 107, 249, 0.2);
      border-radius: 50%;
    }

    .discount-coupon {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      margin: 0 auto;
      width: 4.028vw;
      height: 2.639vw;
      z-index: 1;
      color: #fff;
      background-image: url('/assets/images/ticket.svg');
      background-size: 100% 100%;
      background-position: center center;
      background-repeat: no-repeat;

      .value {
        border: solid;
        padding: 8px 4px 8px 4px;
        border-radius: 12%;
        font-weight: 600;
        font-size: 1.042vw;
        line-height: 1;
      }
    }
  }

  //////////////////////////////////////////
  // Cart button
  //////////////////////////////////////////
  .cart-button-container {
    position: fixed;
    left: -12px;
    bottom: -12px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-radius: 50%;
    box-shadow: 10px -10px 50px -18px rgba(0, 0, 0, 0.2);

    .rtl & {
      left: unset;
      right: -12px;
    }
    .btn-cart {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 42px;
      flex: 0 0 42px;
      color: #fff;
      background: linear-gradient(260.67deg, var(--primary-store) 41.07%, var(--secondary-store));
      border-radius: 50%;

      &.empty {
        background: #b0b5bf;
        .badge {
          display: none;
        }
      }

      i {
        font-size: 22px;
      }

      .badge {
        position: absolute;
        top: -5px;
        right: -5px;
        width: 20px;
        height: 20px;
        background-color: var(--secondary-store);
        border-radius: 50%;

        .rtl & {
          right: unset;
          left: -5px;
        }
      }
    }
  }

  //////////////////////////////////////////
  // Tabs
  //////////////////////////////////////////
  .nav {
    flex-wrap: nowrap;
    margin: 0 -5px 30px;
    width: 100%;
    height: 40px;
    border-radius: 20px;
    .nav-item {
      margin-right: 20px;
      height: 100%;

      .rtl & {
        margin-right: unset;
        margin-left: 20px;
      }

      &:last-child {
        margin-right: 0;
      }
      .nav-link {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 0;
        height: 100%;
        color: #4c3d5b;
        text-align: center;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;

        &.active {
          border-bottom: 2px solid #4c3d5b;
        }

        i {
          margin-right: 15px;
          font-size: 24px;

          .rtl & {
            margin-right: unset;
            margin-left: 15px;
          }
        }
      }
    }
  }

  //////////////////////////////////////////
  // Analytics
  //////////////////////////////////////////
  .stats-container {
    width: 100%;

    .stat-item {
      flex: 0 1 calc(100% / 2);
      margin-bottom: 2.083vw;
      padding-right: 1.389vw;

      font-weight: 700;
      font-size: 20px;
      line-height: 1.5;

      .rtl & {
        padding-right: unset;
        padding-left: 1.389vw;
      }

      .stat-value {
        color: var(--primary-store);
      }
    }
  }
}
