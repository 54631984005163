.orders-modal {
  .modal-dialog {
    .modal-content {
      app-orders-modal {
        $session-col-width: 225px;
        $price-col-width: 150px;
        $order-id-col-width: 230px;
        $shippping-col-width: 190px;
        $invoice-col-width: 135px;

        //////////////////////////////////////////
        // Header
        //////////////////////////////////////////
        .orders-header {
          display: none;
          @include media-breakpoint-up(xl) {
            display: flex;
            align-items: center;
            margin-bottom: 20px;
            padding: 0 20px;
            height: 43px;
            background-color: #f3f6f9;
            border-radius: 6px;
            .column {
              margin-right: 20px;
              font-weight: 500;
              font-size: 13px;
              color: #848491;
              .rtl & {
                margin-right: unset;
                margin-left: 20px;
              }

              &.session {
                width: $session-col-width;
                font-weight: 600;
                font-size: 12px;
                color: #464e5f;
              }

              &.price {
                width: $price-col-width;
              }

              &.order-id {
                width: $order-id-col-width;
              }

              &.shippping {
                width: $shippping-col-width;
              }

              &.invoice {
                width: $invoice-col-width;
              }
            }
          }
        }

        //////////////////////////////////////////
        // Container
        //////////////////////////////////////////
        .orders-container {
          .order-item-grid {
            display: grid;
            grid-template-areas:
              'cover details'
              'cover price'
              'cover invoice'
              'shipping order-id'
              'show-more show-more'
              'order-details order-details';
            grid-template-columns: 120px auto;
            grid-template-rows: auto;
            grid-gap: 10px 40px;
            padding-top: 3px;
            padding-bottom: 35px;
            margin-bottom: 35px;
            border-bottom: 1px solid #b0b5bf;

            @include media-breakpoint-up(md) {
              grid-template-areas:
                'cover details order-id shipping'
                'cover price invoice shipping'
                'cover show-more show-more show-more'
                'order-details order-details order-details';
            }

            @include media-breakpoint-up(xl) {
              grid-template-areas:
                'cover details price order-id shipping invoice'
                'order-details order-details order-details order-details order-details order-details';
              grid-template-columns: 65px 160px $price-col-width $order-id-col-width $shippping-col-width auto;
              grid-gap: 0 20px;
              border-bottom: 0;
            }
            .session-cover {
              grid-area: cover;
            }
            .session-details {
              grid-area: details;
            }
            .price {
              grid-area: price;
            }
            .order-id {
              grid-area: order-id;
            }
            .shipping {
              grid-area: shipping;
            }
            .invoice {
              grid-area: invoice;
            }
            .show-more-button {
              grid-area: show-more;
            }
            .order-details {
              grid-area: order-details;
            }

            .session-cover {
              cursor: pointer;
              height: 158px;
              @include media-breakpoint-up(xl) {
                height: 86px;
              }
              .cover-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 5px;
              }
            }
            .session-details {
              display: flex;
              flex-direction: column;
              .name {
                margin-bottom: 0;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
              }

              .time-date {
                font-size: 13px;
                font-weight: 500;
                line-height: 20px;
                color: #949ba5;
              }
            }

            .price {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;

              .helper-text {
                display: block;
                color: #848491;
                font-weight: 500;
                font-size: 13px;
              }
            }

            .order-id {
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              .helper-text {
                display: block;
                color: #848491;
                font-weight: 500;
                font-size: 13px;
                @include media-breakpoint-up(xl) {
                  display: none;
                }
              }
            }

            .shipping {
              font-size: 12px;
              line-height: 130%;
            }

            .invoice {
              button {
                width: 130px;
                color: var(--primary-store);
                font-size: 16px;
                line-height: 20px;
                background-color: #f3f3f3;
              }
            }

            .show-more-button {
              display: inline-block;
              margin-top: auto;
              color: var(--primary-store);
              font-size: 12px;
              line-height: 18px;
              text-decoration-line: underline;

              ::after {
                display: inline-block;
                margin-left: 8px;
                content: '';
                border-color: var(--primary-store);
                border-bottom: 0.4em solid;
                border-right: 0.35em solid #0000;
                border-top: 0;
                border-left: 0.35em solid #0000;
                .rtl & {
                  margin-left: unset;
                  margin-right: 8px;
                }
              }

              &[aria-expanded='true'] {
                ::after {
                  display: inline-block;
                  content: '';
                  border-color: var(--primary-store);
                  border-top: 0.4em solid;
                  border-right: 0.35em solid #0000;
                  border-bottom: 0;
                  border-left: 0.35em solid #0000;
                }
              }
            }

            .order-details {
              margin-top: 10px;
              padding: 10px 10px 0;
              background-color: #f6f7fb;
              border-radius: 15px;

              .shipping-address,
              .order-summary {
                display: inline-block;
                margin-bottom: 20px;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                vertical-align: text-top;

                h3 {
                  margin-bottom: 0;
                  font-weight: 700;
                  font-size: 14px;
                  line-height: 21px;
                }
              }
              .shipping-address {
                @include media-breakpoint-up(md) {
                  width: 255px;
                }
              }
              .order-summary {
                @include media-breakpoint-up(md) {
                  width: 330px;
                }
              }

              .product-items {
                .product-item {
                  display: grid;
                  grid-template-areas:
                    'image'
                    'title'
                    'price'
                    'properties';
                  grid-template-columns: auto;
                  grid-template-rows: auto;
                  grid-gap: 5px 0;
                  margin-bottom: 15px;

                  @include media-breakpoint-up(md) {
                    grid-template-areas:
                      'image title'
                      'image price'
                      'image properties';
                    grid-template-columns: 86px auto;
                    grid-template-rows: auto;
                    grid-gap: 0 10px;
                    margin-bottom: 20px;
                  }

                  .product-image {
                    grid-area: image;
                    width: 98px;
                    height: 98px;
                    border-radius: 6px;
                    border: 1px solid #000000;
                    object-fit: contain;
                    object-position: center;
                    @include media-breakpoint-up(md) {
                      width: 86px;
                      height: 86px;
                      border-radius: 8px;
                    }
                  }

                  .prduct-name,
                  .price {
                    margin-bottom: 0;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 21px;
                  }

                  .product-properties {
                    @include media-breakpoint-up(xl) {
                      height: 44px;
                    }

                    .property {
                      color: #5e6978;
                      font-weight: 400;
                      font-size: 14px;
                      line-height: 21px;

                      span {
                        font-weight: 600;
                      }

                      @include media-breakpoint-up(md) {
                        display: inline-block;
                        margin-right: 25px;
                      }

                      .rtl & {
                        @include media-breakpoint-up(md) {
                          margin-right: unset;
                          margin-left: 25px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
