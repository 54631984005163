.cart-mobile {
  .pull-up-container {
    .mobile-pull-up-content {
      padding-bottom: 56px;
    }
    .pull-up-content {
      height: 100%;
      position: relative;
      //////////////////////////////////////////
      // Empty cart
      //////////////////////////////////////////
      .empty-cart {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-bottom: 80px;
        width: 100%;
        height: 80%;
        .border-holder {
          padding: 40px 20px;

          img {
            margin-bottom: 20px;
          }

          p {
            font-size: 15px;
            line-height: 113.9%;
            color: #5e6978;
          }
        }
      }
      .button-affiliate {
        position: fixed;
        width: 100%;
        height: 94px;
        background: white;
        z-index: 999;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }

      //////////////////////////////////////////
      // Cart Scroller
      //////////////////////////////////////////
      .cart-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0 10px;
        width: 100%;
        max-height: calc(100% - 63px);
        overflow: auto;
        z-index: 1;

        .cart-item {
          display: flex;
          flex-direction: row;
          margin: 0 10px;
          margin-bottom: 12px;
          margin-top: 0px;
          padding: 7px;
          padding-bottom: 16px;
          flex: 0 0 calc(50% - 20px);
          border-bottom: 1px solid #dde2eb;
          transition: all 300ms ease-out;

          .item-picture {
            position: relative;
            margin: 0 auto 6px;
            width: 48%;
            border-radius: 5px;
            overflow: hidden;

            &::before {
              display: block;
              padding-top: 100%;
            }

            .product-image {
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
            }

            .brand-logo {
              position: absolute;
              top: 1.6vw;
              left: 1.6vw;
              right: 0;
              bottom: 0;
              width: 8.533vw;
              height: 8.533vw;
              object-fit: contain;
              object-position: center;
              z-index: 2;
            }

            .bullets {
              position: absolute;
              top: -0.417vw;
              right: -0.417vw;
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 0.347vw;
              background: #eeeeee;
              border-radius: 0.556vw;
              z-index: 3;

              .rtl & {
                right: 0;
                left: -0.417vw;
              }
            }

            .btn-delete {
              position: absolute;
              top: 0;
              right: 0;
              justify-content: center;
              align-items: center;
              width: 32px;
              height: 32px;
              background-color: #838383;
              border-radius: 50%;
              z-index: 2;

              .rtl & {
                right: unset;
                left: 0;
              }

              img {
                width: 17px;
              }
            }

            .btn-customization {
              position: absolute;
              left: 5px;
              bottom: 5px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              width: 20px;
              height: 20px;
              background-color: var(--primary-store);
              border-radius: 10px;
              z-index: 2;
              overflow: hidden;

              transition: width 300ms ease-in-out;

              .rtl & {
                left: unset;
                right: 5px;
              }

              &:hover {
                width: 95px;
              }

              .icon {
                margin: 2px 5px 0px;
              }

              .text {
                color: #ffffff;
                font-size: 9.5px;
                font-weight: 600;
                text-align: center;
                text-transform: uppercase;
              }
            }
          }

          .item-title {
            margin-bottom: 2px;
            font-size: 14px;
            line-height: 18px;
            @extend .line-clamp-1;
          }

          .item-price {
            color: var(--secondary-store);
            margin-bottom: 2px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;

            .previous-price {
              color: #5e6978;
              font-size: 15px;
              font-weight: normal;
              text-decoration: line-through;
            }
          }

          .item-properties {
            padding-top: 7px;
            .property {
              display: flex;
              align-items: center;
              margin-bottom: 2px;
              font-size: 12px;
              line-height: 18px;
              .property-name {
                margin-right: 4px;
                color: #949ba5;

                .rtl & {
                  margin-right: unset;
                  margin-left: 4px;
                }
              }
              .property-value {
                color: #000;
                font-weight: 700;
              }
              .minusAndPlus-properties {
                margin-top: 4px;
              }
              .btn-minus {
                width: 24px;
                height: 24px;
                margin-left: auto;
                .rtl & {
                  margin-left: unset;
                  margin-right: auto;
                }
                img {
                  width: 100%;
                }
              }
              .btn-add {
                width: 24px;
                height: 24px;
                margin-left: 10px;
                .rtl & {
                  margin-left: unset;
                  margin-right: 10px;
                }
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
  }
}
.flickeringItems-cart {
  position: absolute;
  bottom: 11px;
  height: 60px;
  width: 60px;
  // right: 2px;
  z-index: 5;
}
