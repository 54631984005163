//////////////////////////////
/// Desktop                ///
//////////////////////////////
.d-heart1,
.d-heart4,
.d-heart7,
.d-heart10,
.d-heart13,
.d-heart16 {
  path {
    fill: var(--primary-store);
  }
}
.d-heart2,
.d-heart5,
.d-heart8,
.d-heart11,
.d-heart14,
.d-heart17 {
  path {
    fill: var(--secondary-store);
  }
}
.d-heart3,
.d-heart6,
.d-heart9,
.d-heart12,
.d-heart15 {
  path {
    fill: var(--light-store);
  }
}

.d-base:nth-child(1) {
  path {
    stroke: var(--primary-store);
  }
}
.d-base:nth-child(2) {
  path {
    fill: var(--primary-store);
  }
}

.d-circle {
  path {
    fill: #f6f7fb;
  }
}

//////////////////////////////
/// Mobile                 ///
//////////////////////////////
.heart1,
.heart4,
.heart7,
.heart10,
.heart13,
.heart16 {
  path {
    fill: var(--primary-store);
  }
}
.heart2,
.heart5,
.heart8,
.heart11,
.heart14,
.heart17 {
  path {
    fill: var(--secondary-store);
  }
}
.heart3,
.heart6,
.heart9,
.heart12,
.heart15 {
  path {
    fill: var(--light-store);
  }
}

.heart-base {
  path {
    fill: var(--primary-store);
    stroke: var(--light-store);
  }
}
