//////////////////////////////////////////
// Header
//////////////////////////////////////////
:root {
  --color-btn-back-to-store: #949ba5;
}
header {
  position: relative;
  z-index: 1020;
  background-color: #f6f7fb;
  box-shadow: 0 0 10px 0 rgba(#000, 0.2);
  .navbar {
    padding: 0 10px;
    height: 60px;
    @include media-breakpoint-up(xl) {
      padding: 0 34px;
      height: 78px;
    }
  }
  .header-start {
    display: flex;
    flex-direction: row;
    align-items: center;

    .to-store {
      background-color: #fff;
      border-radius: 30px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 7px 23px 5px 40px;
      color: var(--color-btn-back-to-store);
      margin-left: 108px;
      margin-top: 5px;
      font-size: 14px;

      .rtl & {
        padding: 7px 40px 5px 23px;
        margin-left: unset;
        margin-right: 40px;
      }

      img {
        position: absolute;
        left: 19px;
        top: 50%;
        transform: translateY(-50%);

        .rtl & {
          left: unset;
          right: 19px;
          transform: translateY(-50%) scaleX(-1);
        }
      }
    }

    .navbar-toggler {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      width: 30px;
      height: 30px;
      color: #050e2e;
      background: rgba(#050e2e, 0.2);
      border: 0;
      border-radius: 50%;
      box-shadow: none;
      @include media-breakpoint-up(md) {
        width: 46.28px;
        height: 46.28px;
      }

      .navbar-toggler-icon {
        width: 20px;
        height: 20px;
        @include media-breakpoint-up(md) {
          width: 30px;
          height: 30px;
        }
      }
    }

    .navbar-brand {
      img {
        height: 23px;
        @include media-breakpoint-up(md) {
          height: 28px;
        }
      }
    }
  }
  .header-end-mobile {
    gap: 8px;
  }
  .header-end-desktop {
    flex-direction: row;
  }
  .header-end {
    display: flex;
    align-items: center;
    .dropdown {
      position: static;

      .user-dropdown-button {
        display: flex;
        align-items: center;
        padding: 0;
        color: #949ba5;
        font-weight: normal;
        text-decoration: none;
        border: 0;
        background-color: transparent;
        background-image: none;

        @include media-breakpoint-up(xl) {
          font-size: 14px;
        }

        &::after {
          display: inline-block;
          margin-left: 15px;
          vertical-align: 1px;
          content: '';
          border-color: #5e6978;
          border-top: 0.4em solid;
          border-right: 0.4em solid #0000;
          border-bottom: 0;
          border-left: 0.4em solid #0000;

          .rtl & {
            margin-left: unset;
            margin-right: 15px;
          }
        }

        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          object-position: center;
          border-radius: 50%;

          @include media-breakpoint-up(md) {
            margin-right: 10px;
          }

          .rtl & {
            @include media-breakpoint-up(md) {
              margin-right: unset;
              margin-left: 10px;
            }
          }
        }

        .user-name {
          display: none;
          @include media-breakpoint-up(md) {
            display: block;
          }
        }
      }

      .user-dropdown {
        position: absolute;
        right: 7.5px;
        left: auto;
        top: 70px;
        margin: 0;

        @include media-breakpoint-up(xl) {
          top: 88px;
        }

        .rtl & {
          @include media-breakpoint-up(xl) {
            right: auto;
            left: 7.5px;
          }
        }
      }
    }

    .language-dropdown {
      @include media-breakpoint-up(sm) {
        position: relative;
      }
      .language-name {
        text-transform: uppercase;
        font-weight: 500;
        margin: 0;
        font-size: 14px;
      }
      .language-dropdown-button {
        padding: 0 5px;
        border: 0;
        background-color: transparent;
        background-image: none;
        @include media-breakpoint-up(md) {
          padding: 0 20px;
        }
        &::after {
          display: none;
        }
      }

      .language-dropdown-menu {
        margin-top: 18px;
        padding: 20px;
        width: 400px;
        min-width: auto;
        background: #f6f7fb;
        box-shadow: 0px 20px 50px -18px rgba(0, 0, 0, 0.8);
        border-radius: 20px;
        border: 0;

        .rtl & {
          left: 0 !important;
          right: auto !important;
        }

        @include media-breakpoint-down(sm) {
          margin-top: 0px;
          top: 70px !important;
          left: 7.5px !important;
          right: 7.5px !important;
          width: auto;
          transform: none !important;

          .rtl & {
            left: 7.5px !important;
            right: 7.5px !important;
          }
        }

        .title {
          margin-bottom: 20px;
          color: #333838;
          font-size: 20px;
          line-height: 20px;
          letter-spacing: -0.03em;
          text-transform: capitalize;
          img {
            margin-right: 24px;
            .rtl & {
              margin-right: 0;
              margin-left: 24px;
            }
          }
        }

        .currency-button {
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          padding: 10px 22px 10px 28px;
          width: 100%;
          height: 70px;
          color: #58575a;
          font-size: 16px;
          line-height: 120%;
          background-color: rgba(#9aa4ad, 0.3);
          border-radius: 20px;
          border: 0;

          i.flag {
            margin-right: 8px;
          }
          .arrow {
            margin-left: auto;
            .rtl & {
              margin-left: unset;
              margin-right: auto;
            }
          }
        }

        .languages-buttons {
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          height: 40px;

          .language-select-button {
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: -0.03em;
            text-transform: capitalize;
            color: #9e9ea9;

            &.active {
              color: #58575a;
              font-weight: bold;
              pointer-events: none;
              cursor: default;
            }
          }
        }
      }
    }

    .btn-base {
      width: 150px;
      @include media-breakpoint-up(md) {
        margin: 0 0 0 12px;
      }

      .rtl & {
        @include media-breakpoint-up(md) {
          margin: 0 12px 0 0;
        }
      }
    }
  }
}
body[client-name='claro'] {
  header {
    .header-start {
      .navbar-brand {
        img {
          height: 34px;
          margin-left: 12px;
          @include media-breakpoint-up(md) {
            height: 44px;
            margin-left: 100px;
          }
        }
      }
    }
  }
}
