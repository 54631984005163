//////////////////////////////////////////
// Homepage
//////////////////////////////////////////
.homepage {
  overflow: hidden;
  font-family: 'Montserrat', 'Heebo', sans-serif;
  background-repeat: no-repeat;
  background-size: 100% auto;
  @include media-breakpoint-up(xl) {
    background-image: none;
  }
  .top-left-bg-mobile {
    position: fixed;
    background: linear-gradient(
      128deg,
      #f77dbc 23.13%,
      #f3f3f3 56.05%,
      #ffb72c 47.46%,
      #f5a161 80.26%,
      #f5a161 80.26%
    );
    width: calc(var(--vh, 1vh) * 49);
    height: calc(var(--vh, 1vh) * 31);
    top: calc(var(--vh, 1vh) * 8);
    left: calc(var(--vh, 1vh) * -22);
    filter: blur(79px);
    border-radius: 293.111px;
    transform: rotate(176deg);
  }
  .right-bg-mobile {
    position: fixed;
    background: linear-gradient(
      200deg,
      #f77dbc 23.13%,
      #f3f3f3 56.05%,
      #ffb72c 47.46%,
      #f5a161 80.26%,
      #f5a161 80.26%
    );
    width: calc(var(--vh, 1vh) * 34);
    height: calc(var(--vh, 1vh) * 36);
    top: calc(var(--vh, 1vh) * 78);
    left: calc(var(--vh, 1vh) * 32);
    filter: blur(74px);
    transform: rotate(176deg);
  }
  //////////////////////////////////////////
  // BG Elements
  //////////////////////////////////////////
  .bg-elements {
    position: fixed;
    top: 0;
    left: 0;
    right: -53px;
    z-index: 1;

    @include media-breakpoint-down(lg) {
      display: none; // hide on mobile
    }

    .top-right-bg {
      position: absolute;
      right: 0;
      z-index: 1;
      pointer-events: none;

      .rtl & {
        right: unset;
        left: 0;
        transform: scaleX(-1);
      }
      width: 780.71px;
      height: 613.59px;
      left: 80%;
      top: -120.08px;
      background: linear-gradient(
        151deg,
        #f77dbc 39.13%,
        #f3f3f3 56.05%,
        #ffb72c 60.46%,
        #f5a161 80.26%,
        #f5a161 80.26%
      );
      filter: blur(107px);
      border-radius: 293.111px;
      transform: rotate(176deg);
    }

    .left-bg {
      position: absolute;
      top: 357px;
      left: 0;
      width: 16%;
      z-index: 1;
      pointer-events: none;
      .rtl & {
        left: unset;
        right: 0;
        transform: scaleX(-1);
      }
      position: absolute;
      width: 344.65px;
      height: 396.65px;
      left: -226.51px;
      top: 447.77px;
      background: linear-gradient(229.08deg, #f77dbc 39.13%, #f5a161 80.26%, #f5a161 80.26%);
      filter: blur(88px);
      border-radius: 1000px;
      transform: rotate(155.26deg);
    }

    .bottom-right-bg {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 82%;
      z-index: 1;
      pointer-events: none;
      .rtl & {
        right: unset;
        left: 0;
        transform: scaleX(-1);
      }
    }
  }

  //////////////////////////////////////////
  // Main
  //////////////////////////////////////////
  main {
    position: relative;
    flex-grow: 1;
    z-index: 1;
    @include media-breakpoint-up(xl) {
      margin: 0 auto;
      width: 1160px;
    }

    @include media-breakpoint-up(xxl) {
      width: 1475px;
    }
  }

  //////////////////////////////////////////
  // Cover section
  //////////////////////////////////////////
  section.cover-section {
    display: flex;
    flex-wrap: wrap;
    padding-top: 32px;

    @include media-breakpoint-up(xl) {
      padding-top: 0;
      flex-wrap: nowrap;
    }

    .text-container {
      flex: 0 0 100%;
      @include media-breakpoint-up(xl) {
        padding-top: 130px;
        flex: 0 0 50%;
      }

      .title {
        margin-bottom: 5.333vw;
        color: #4c4769;
        font-weight: 700;
        font-size: 9.333vw;
        line-height: 104%;
        letter-spacing: -0.01em;
        font-feature-settings: 'liga' off;
        text-align: center;
        @include media-breakpoint-up(xl) {
          width: 570px;
          margin-bottom: 40px;
          text-align: left;
          font-size: 80px;
        }

        .rtl & {
          @include media-breakpoint-up(xl) {
            text-align: right;
          }
        }
      }

      .lead {
        margin-bottom: 8vw;
        padding: 0 5.333vw;
        color: #4c4769;
        text-align: center;
        font-size: 3.733vw;
        font-weight: 500;
        line-height: 143%;
        font-feature-settings: 'liga' off;
        @include media-breakpoint-up(xl) {
          margin-bottom: 110px;
          padding: 0;
          line-height: 128%;
          width: 570px;
          text-align: left;
          font-size: 25px;
        }
        @include media-breakpoint-up(xxl) {
          width: 686px;
        }

        .rtl & {
          @include media-breakpoint-up(xl) {
            text-align: right;
          }
        }
      }

      .go-shopping-btn {
        display: flex;
        align-items: center;
        text-align: center;
        margin: 0 auto;
        padding: 0;
        color: #ef60ab;
        font-size: 4.8vw;
        font-weight: 600;
        line-height: 100%;
        text-decoration: none;
        border: 0;
        background-color: unset;
        @include media-breakpoint-up(xl) {
          margin: 0;
          font-size: 30px;
          font-weight: 700;
        }

        svg {
          margin-left: 2.667vw;
          width: 5.333vw;
          height: 5.333vw;
          @include media-breakpoint-up(xl) {
            margin-left: 15px;
            width: 38px;
            height: 38px;
          }

          .rtl & {
            @include media-breakpoint-up(xl) {
              margin-left: unset;
              margin-right: 15px;
            }
          }
        }
      }
    }

    .graphics-container {
      display: flex;
      justify-content: center;
      flex: 0 0 100%;
      padding-top: 0;
      @include media-breakpoint-up(xl) {
        padding-top: 74px;
        justify-content: flex-end;
        align-items: flex-start;
        flex: 0 0 50%;
      }

      .graphics-containe-inner {
        position: relative;
        width: 90%;
        @include media-breakpoint-up(xl) {
          margin-right: -10%;
          width: 110%;
        }
        @include media-breakpoint-up(xxl) {
          margin-right: 0;
          width: 100%;
        }

        .rtl & {
          @include media-breakpoint-up(xl) {
            margin-right: unset;
            margin-left: -10%;
          }
          @include media-breakpoint-up(xxl) {
            margin-right: unset;
            margin-left: 0;
          }
        }

        .circle-image-container {
          position: relative;
          z-index: 1;
          width: 100%;

          .circle-image {
            width: 100%;
          }

          .circle-hand-container {
            aspect-ratio: 1/1;
            top: 11%;
            z-index: 1;
            border-radius: 50%;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10.9758px;
            position: initial;
            width: 100%;
            height: 100%;
            left: 147px;
            margin-top: 75px;
            background: linear-gradient(180deg, #ef60ab 0%, #e13891 100%);
            position: -webkit-sticky;

            .circle-hand-image {
              position: relative;
              width: 100%;
              border-radius: inherit;
              left: 4.5%;
            }
          }
        }

        .avatar-image {
          position: absolute;
          z-index: 1;

          &.avatar-image-1 {
            top: 0;
            left: 0;
            width: 24%;

            img {
              animation-delay: 700ms;
            }
          }

          &.avatar-image-2 {
            top: 26.7%;
            left: 63.8%;
            width: 16.7%;

            img {
              animation-delay: 1100ms;
            }
          }

          &.avatar-image-3 {
            top: 78.5%;
            left: 11.2%;
            width: 22.1%;

            img {
              animation-delay: 1350ms;
            }
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }
  .character1 {
    position: absolute;
    right: 5%;
  }
  .character2 {
    border: 2.90449px solid #ffffff;
    border-radius: 778.427px;
    filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.25));
  }
  .character3 {
    border: 2.5px solid #ffffff;
    border-radius: 1148.91px;
  }
  .floating {
    animation-name: floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 1s;
    transform: translateZ(0);
  }

  @keyframes floating {
    0% {
      transform: translate(0, 0px);
    }
    50% {
      transform: translate(0, 10px);
    }
    100% {
      transform: translate(0, -0px);
    }
  }

  //////////////////////////////////////////
  // Stores section
  //////////////////////////////////////////
  section.stores-section {
    padding-top: 20.267vw;
    @include media-breakpoint-up(xl) {
      padding-top: 125px;
    }

    @include media-breakpoint-up(xxl) {
      padding-top: 175px;
    }

    h3 {
      margin-bottom: 12.267vw;
      color: #4c4769;
      text-align: center;
      font-weight: bold;
      font-feature-settings: 'liga' off;
      @include media-breakpoint-up(xl) {
        margin-bottom: 72px;
        text-align: left;
        font-size: 36px;
        line-height: 98%;
      }

      .rtl & {
        @include media-breakpoint-up(xl) {
          text-align: right;
        }
      }

      .text-pink {
        color: #ef60ab;
      }
    }

    .stores-container {
      display: flex;
      flex-wrap: wrap;
      padding: 0 4vw;
      margin-bottom: 85px;

      @include media-breakpoint-up(xl) {
        padding: 0;
        margin: 0 -50px;
      }

      .store-item {
        opacity: 1;
        display: block;
        text-decoration: none;
        flex: 0 0 calc(50% - 8vw);
        margin: 0 4vw 13.333vw;

        @include media-breakpoint-up(xl) {
          flex: 0 0 215px;
          margin: 0 50px 84px;
        }

        .logo-holder {
          margin: 0 2.667vw;
          padding: 16px 22px;
          height: 43.2vw;
          background-color: #ffffff;
          box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
          border-radius: 5px 5px 0 0;
          @include media-breakpoint-up(xl) {
            margin: 0 32px;
            height: 162px;
          }

          .logo {
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }

          .store-type-banner {
            margin-left: -22px;
            margin-top: -16px;
            height: 40px;
            width: calc(100% + 45px);
            border-radius: 4px 4px 0px 0px;
            opacity: 0.7;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            z-index: 1;

            .store-type-banner-text {
              color: #000;
              font-family: Montserrat;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
            }
          }
        }

        .text-holder {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 0 2.667vw;
          height: 24vw;
          background-color: #4c4769;
          box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          @include media-breakpoint-up(xl) {
            padding: 0 20px;
            height: 108px;
          }
          .brand-name {
            color: #fff;
            font-weight: bold;
            font-size: 5.333vw;
            line-height: 120%;
            letter-spacing: -0.03em;
            word-break: break-word;
            @include media-breakpoint-up(xl) {
              font-size: 24px;
            }
          }

          .arrow {
            width: 9.6vw;
            height: 9.6vw;
            @include media-breakpoint-up(xl) {
              width: 36px;
              height: 36px;
            }
          }
        }
      }
    }
  }

  .scroll-to-top {
    position: relative;
    display: none;
    z-index: 1;
    @include media-breakpoint-up(xl) {
      display: block;
      margin: 0 20px 44px auto;
      background-color: unset;
      border: 0;
    }

    .rtl & {
      @include media-breakpoint-up(xl) {
        margin: 0 auto 44px 20px;
      }
    }
  }
}
@media (max-width: 700px) {
  .swiper-erea {
    .swiper-slide {
      transition: all 0.3s;
      height: fit-content;
    }
    .swiper-items {
      .swiper-slide.swiper-slide-next {
        margin-left: 0%;
      }
      .swiper-slide.swiper-slide-prev {
        left: 0%;
      }
    }
  }
}
.swiper-upcoming {
  .swiper-slide.swiper-slide-next {
    margin-left: 0%;
  }
  .swiper-slide.swiper-slide-prev {
    left: 0%;
  }
}
.swiper-Last {
  .swiper-slide.swiper-slide-next {
    margin-left: 0%;
  }
  .swiper-slide.swiper-slide-prev {
    left: 0%;
  }
}
