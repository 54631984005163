.store-settings {
  h2 {
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 20px;
  }
  h3 {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 18px;
  }
  h5 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 0;
  }

  .settings-blocks {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 950px;
    padding: 24px 0 0 0;
    margin-top: 8px;

    .settings-row {
      display: flex;
      flex-wrap: wrap;
      .brand-colors {
        .select-color {
          margin-right: 20px;
        }
        &.element {
          flex: 0px;
        }
      }
      .element {
        margin: 0 25px 25px 0;
        flex: 0 0 30%;
        .rtl & {
          margin: 0 0 25px 25px;
        }
        textarea {
          all: unset;
          box-sizing: border-box;
          width: 100%;
          max-height: 165px;
          border-radius: 10px;
          background-color: #f5f5f5;
          font-size: 16px;
          padding: 15px 10px;
          font-weight: 400;
          margin-top: 20px;
        }
        .element-desc {
          min-height: 35px;
          display: flex;
          justify-content: space-between;
          &.color-block {
            flex-direction: row;
            display: flex;
            justify-content: initial !important;
          }
          .red {
            display: inline-block;
            margin-left: 15px;
            cursor: pointer;
            .rtl & {
              margin-left: unset;
              margin-right: 15px;
            }
          }
          input[type='file'] {
            display: none;
          }
          .color-input {
            display: flex;
            flex-wrap: wrap;
            position: relative;
            height: 30px;
            justify-content: center;
            align-items: center;
            .color-circle {
              position: absolute;
              width: 24px;
              height: 24px;
              border-radius: 50%;
              left: 3px;
              top: 50%;
              transform: translateY(-50%);
              .rtl & {
                left: unset;
                right: 3px;
              }
            }
          }

          .color-picker-input {
            all: unset;
            margin-right: 15px;
            padding-left: 40px;
            width: 100%;
            height: 30px;
            font-size: 12px;
            background-color: #f5f5f5;
            border-radius: 20px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            .rtl & {
              margin-right: unset;
              margin-left: 15px;
              padding-left: unset;
              padding-right: 40px;
            }
          }
        }
      }
    }
  }
  .preview {
    position: relative;
    margin-top: 15px;
    height: 150px;
    border-radius: 10px;
    border: 1px solid #c4c4c4;
    object-fit: contain;
    object-position: center center;
    display: flex;
    justify-content: center;
    max-width: 300px;
    overflow: hidden;
    img {
      width: 100%;
      height: 148px;
      object-fit: contain;
      object-position: center;
    }

    .svg-container {
      width: 100%;
      height: 148px;
      svg {
        width: 100%;
        height: 100%;
      }
    }

    .btn-remove {
      position: absolute;
      top: 5px;
      right: 5px;
      .rtl & {
        right: unset;
        left: 5px;
      }
    }
  }
  .size-table {
    margin-bottom: 60px;
    .no-style-dropzone-content {
      display: flex;
    }
    p {
      max-width: 360px;
    }
    .file {
      display: none;
    }
    .file-name {
      all: unset;
      position: relative;
      padding: 0 14px;
      height: 50px;
      width: 300px;
      font-size: 16px;
      vertical-align: middle;
      color: #949ba5;
      background-color: #f5f5f5;
      border-radius: 10px;
      overflow: hidden;
    }
    .btn {
      height: 50px;
      width: 190px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      border-radius: 5px;
      box-sizing: border-box;
      padding: 2px 10px;
      background-color: #01befa;
      transition: all 0.6s;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      vertical-align: middle;
      margin-left: 10px;
      .rtl & {
        margin-left: unset;
        margin-right: 10px;
      }
    }
  }

  .invalid-feedback {
    margin-top: 10px;
    svg {
      margin-right: 5px;
      vertical-align: text-top;
      .rtl & {
        margin-right: unset;
        margin-left: 5px;
      }
    }
  }
}

//////////////////////////////////////////
// Store-shipping
//////////////////////////////////////////
.store-shipping {
  h2 {
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 20px;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
  .icon-input {
    display: flex;
    position: relative;
    height: 50px;
  }
  input {
    all: unset;
    box-sizing: border-box;
    width: 100%;
    max-width: 300px;
    border-radius: 10px;
    padding: 13px 19px;
    background-color: #f5f5f5;
  }
  input[type='number'] {
    margin-left: 10px;
    width: 100%;
    max-width: 120px;
    .rtl & {
      margin-left: unset;
      margin-right: 10px;
    }
  }
}

//////////////////////////////////////////
// Publish
//////////////////////////////////////////
.status {
  display: flex;
  .desc {
    display: flex;
    flex: 6;
  }
  .switcher {
    margin-left: 50px;
    flex: 4;

    .rtl & {
      margin-left: unset;
      margin-right: 50px;
    }

    input {
      display: none;
    }
    .block {
      position: relative;
      margin: 0 0 25px;
      float: left;
      display: flex;
      align-items: center;
      .rtl & {
        float: right;
      }
    }
    span {
      font-size: 19px;
      font-weight: 500;
      margin: 0 0 0 16px;
      .rtl & {
        margin: 0 16px 0 0;
      }
    }
    label {
      margin-bottom: 0;
      width: 69px;
      height: 36px;
      box-sizing: border-box;
      border: 1px solid #bdbdbd;
      float: left;
      border-radius: 100px;
      position: relative;
      cursor: pointer;
      transition: 0.3s ease;
      .rtl & {
        float: right;
      }
    }
    input[type='checkbox']:checked + label {
      // background: #55e868;
    }
    input[type='checkbox']:checked + label:before {
      content: url(./../../assets/images/icons/eye.svg);
      left: 34px;
      background-image: linear-gradient(180deg, #01bdfa 0%, #01fbff 100%);
      .rtl & {
        left: unset;
        right: 34px;
      }
    }
    label:before {
      transition: 0.3s ease;
      content: url(./../../assets/images/icons/closed_eye.svg);
      width: 30px;
      height: 30px;
      position: absolute;
      background: white;
      left: 2px;
      top: 2px;
      padding-top: 2px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      color: black;
      border-radius: 100px;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center center;
      background-color: #dde2eb;
      background-image: none;

      .rtl & {
        left: unset;
        right: 2px;
      }
    }
  }
}

.shipping-methods {
  margin-bottom: 65px;
}

.store-currency {
  .currency-button {
    display: inline-flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 10px 22px 10px 28px;
    min-width: 286px;
    height: 70px;
    color: #58575a;
    font-size: 16px;
    line-height: 120%;
    background-color: #f5f5f5;
    border-radius: 20px;
    border: 0;

    i.flag {
      margin-right: 8px;
      .rtl & {
        margin-right: unset;
        margin-left: 8px;
      }
    }
    .arrow {
      margin-left: auto;
      .rtl & {
        margin-left: unset;
        margin-right: auto;
        transform: scaleX(-1);
      }
    }
  }
}

.tax-rates {
  margin-bottom: 65px;
  .custom-input-icon-right {
    height: 100%;
    input {
      padding: 13px 35px 13px 19px;

      .rtl & {
        padding: 13px 19px 13px 35px;
      }
    }
  }
}

.action-btns {
  display: flex;
  padding: 30px 0 60px;
  .save {
    width: auto;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #3bd984;
    transition: 0.4s;
    &:hover {
      background-color: #00c48c;
    }
  }
  .refresh-btn {
    display: flex;
    position: relative;
    width: 100%;
    max-width: 140px;
    height: 50px;
    margin-left: 45px;
    display: flex;
    align-items: center;
    padding-left: 35px;
    position: relative;

    .rtl & {
      margin-left: unset;
      margin-right: 45px;
      padding-left: unset;
      padding-right: 35px;
    }

    img {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      .rtl & {
        left: unset;
        right: 0;
      }
    }
  }
}
