//////////////////////////////////////////
// Modals
//////////////////////////////////////////
.modal {
  .modal-dialog {
    @include media-breakpoint-down(sm) {
      margin: 0;
      width: 100%;
      min-height: 100%;
      max-width: 100%;
    }

    .modal-content {
      margin: 54px 0px;
      padding: 20px 20px 20px;
      height: 100%;
      border: 0;

      @include media-breakpoint-down(lg) {
        background: linear-gradient(
          180deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(228, 228, 228, 0.9) 100%
        );
        backdrop-filter: blur(35px);
      }

      @include media-breakpoint-up(xl) {
        padding: 20px;
        border-radius: 20px;
      }

      .modal-title {
        margin: 28px 0;
        font-weight: 500;
        font-size: 20px;
        text-align: center;
        @include media-breakpoint-up(xl) {
          margin-top: 0;
          margin-bottom: 35px;
          text-align: left;
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
        }

        .rtl & {
          @include media-breakpoint-up(xl) {
            text-align: right;
          }
        }
      }

      .btn-close-modal {
        display: none;
        @include media-breakpoint-up(xl) {
          display: block;
          position: absolute;
          top: 20px;
          right: 32px;
          width: 27px;
          height: 27px;
          z-index: 1;
        }

        .rtl & {
          @include media-breakpoint-up(xl) {
            right: unset;
            left: 32px;
          }
        }

        img {
          width: 100%;
        }
      }

      .btn-close-modal-mobile {
        display: block;
        position: absolute;
        top: 24px;
        right: 24px;
        width: 27px;
        height: 27px;
        z-index: 1;
        @include media-breakpoint-up(xl) {
          display: none;
        }

        .rtl & {
          right: unset;
          left: 24px;
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

.light-backdrop {
  background: rgba(#fff, 0.8);
}
