$pull-up-bg-white: escape-svg(
  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="375" height="1538" viewBox="0 0 375 1538"><title>pull-up-bg-gray</title><path d="M188.313.334c10.442,1.994,20.01,9.761,30.64,9.761H355a20,20,0,0,1,20,20V1548.07a30,30,0,0,1-30,30H30a30,30,0,0,1-30-30V30.1a20,20,0,0,1,20-20H150.592c10.63,0,20.2-7.767,30.64-9.761a18.932,18.932,0,0,1,7.081,0ZM180.1,18.125a3,3,0,0,1-3.254-5.041l1.615,2.488c-1.615-2.488-1.614-2.489-1.613-2.489h0l0,0,.007,0,.016-.011.046-.029.139-.085c.114-.068.269-.158.462-.263a16.406,16.406,0,0,1,1.577-.749,14.066,14.066,0,0,1,5.27-1.112,15.389,15.389,0,0,1,5.366,1.084c.684.261,1.253.522,1.657.722.2.1.367.186.486.25l.144.079.045.025.016.009.007,0,0,0h0s0,0-1.442,2.5L192.1,13a3,3,0,0,1-2.995,5.2h0l0,0h0v0m.006,0-.006,0-.061-.034c-.062-.033-.162-.086-.3-.152-.269-.133-.664-.314-1.142-.5a9.477,9.477,0,0,0-3.226-.689,8.112,8.112,0,0,0-2.988.661,10.381,10.381,0,0,0-.991.47c-.114.062-.2.11-.244.139l-.045.027h0m8.993.073Z" style="fill:#ffffff"/><circle cx="185" cy="19" r="13" style="fill:#ff53a1"/><path d="M190,18l-5,4-5-4" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px"/></svg>')
);

$pull-up-bg-gray: escape-svg(
  url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="375" height="1538" viewBox="0 0 375 1538"><title>pull-up-bg-gray</title><path d="M188.313.334c10.442,1.994,20.01,9.761,30.64,9.761H355a20,20,0,0,1,20,20V1548.07a30,30,0,0,1-30,30H30a30,30,0,0,1-30-30V30.1a20,20,0,0,1,20-20H150.592c10.63,0,20.2-7.767,30.64-9.761a18.932,18.932,0,0,1,7.081,0ZM180.1,18.125a3,3,0,0,1-3.254-5.041l1.615,2.488c-1.615-2.488-1.614-2.489-1.613-2.489h0l0,0,.007,0,.016-.011.046-.029.139-.085c.114-.068.269-.158.462-.263a16.406,16.406,0,0,1,1.577-.749,14.066,14.066,0,0,1,5.27-1.112,15.389,15.389,0,0,1,5.366,1.084c.684.261,1.253.522,1.657.722.2.1.367.186.486.25l.144.079.045.025.016.009.007,0,0,0h0s0,0-1.442,2.5L192.1,13a3,3,0,0,1-2.995,5.2h0l0,0h0v0m.006,0-.006,0-.061-.034c-.062-.033-.162-.086-.3-.152-.269-.133-.664-.314-1.142-.5a9.477,9.477,0,0,0-3.226-.689,8.112,8.112,0,0,0-2.988.661,10.381,10.381,0,0,0-.991.47c-.114.062-.2.11-.244.139l-.045.027h0m8.993.073Z" style="fill:#f6f7fb"/><circle cx="185" cy="19" r="13" style="fill:#ff53a1"/><path d="M190,18l-5,4-5-4" style="fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:3px"/></svg>')
);

.pull-up-container {
  .pull-up-content {
    .pull-up-head {
      .title {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0;
        font-weight: 300;
        font-size: 20px;
        line-height: 30px;
        color: #5e6978;

        i {
          margin-right: 10px;
          font-size: 26px;

          .rtl & {
            margin-right: unset;
            margin-left: 10px;
          }
        }
      }

      .btn-back {
        display: flex;
        align-items: center;
        margin: 0 15px;
        flex: 0 0 auto;
        padding: 0 10px;
        height: 28px;
        color: #58575a;
        font-size: 12px;
        font-weight: 700;
        line-height: 18px;
        text-transform: uppercase;
        background-color: transparent;
        i {
          display: inline-block;
          font-size: 19px;
          margin-right: 5px;
          vertical-align: bottom;
          .rtl & {
            margin-right: unset;
            margin-left: 5px;
            transform: scaleX(-1);
          }
        }
      }
    }
  }
}
