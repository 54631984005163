.offline-session-content {
  position: relative;
  //////////////////////////////////////////
  // Session details
  //////////////////////////////////////////
  .session-details {
    margin: 2.208vw 0 1.806vw;
    flex-grow: 1;
    flex-shrink: 0;
    div:has(.session-details-inner) {
      display: flex;
      justify-content: center;
    }
    .session-details-inner {
      width: 60%;
      .rtl & {
        margin-right: 53px;
      }
      .time-and-date {
        display: flex;
        align-items: center;
        line-height: 1;
        justify-content: center;
        i {
          margin-right: 0.833vw;
          font-size: 1.667vw;

          .rtl & {
            margin-right: unset;
            margin-left: 0.833vw;
          }
        }

        .text-bold {
          font-weight: 700;
          width: 100%;
        }

        .countdown-text {
          display: inline-block;
        }
      }

      .organizer-picture {
        margin-bottom: 16px;
        width: 284px;
        height: 340px;
        border-radius: 1.042vw;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          aspect-ratio: 1;
          object-fit: cover;
          object-position: center top;
        }
      }

      #organizer-picture-presession-desktop {
        width: 100%;
        height: unset;
        img {
          aspect-ratio: unset;
        }
      }

      .remind-me-btn {
        margin-bottom: 33px;
        display: flex;
        justify-content: center;
      }

      .title {
        font-size: 1.111vw;
        font-weight: 600;
        line-height: 120%;
      }

      .description-text {
        font-size: 0.972vw;
        word-wrap: break-word;
        white-space: pre-line;
      }

      .seperator {
        margin: 1.806vw 0;
        width: 100%;
        height: 1px;
        background-color: #dde2eb;
      }

      .discount-details {
        gap: 8px;
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: 14px;
        .up-to-before-coupon {
          margin-right: 0.694vw;
          margin-top: revert;
        }
        .discount-coupon {
          margin-right: 0.694vw;
          .rtl & {
            margin-right: unset;
            margin-left: 0.694vw;
          }

          .value {
            border: solid;
            padding: 4px 2px 4px 2px;
            border-radius: 10%;
            color: var(--secondary-store);
            font-weight: 600;
            font-size: 1vw;
            line-height: 1;
          }
        }
      }

      .btn-remind-me {
        &.active {
          background: #b0b5bf;
        }
      }
    }
  }

  //////////////////////////////////////////
  // Session products
  //////////////////////////////////////////
  .container {
    padding-top: 24px;
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 2000px;
    justify-content: space-between;
    flex-direction: row;
  }
  .session-details-container {
    position: relative;
    height: 100%;
    display: flex;
    width: 420px;
    flex-direction: column;
    justify-content: flex-start;
  }
  .future-sessions-header {
    margin-left: 8px;
    margin-top: 28px;
    height: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 12px;
  }
  .future-sessions-text {
    font-weight: 400;
    font-size: 30px;
    line-height: 40px;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 0px;
  }
  ::-webkit-scrollbar {
    width: 0px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0);
    /* box-shadow: 0 5px 20px black;  */
    border-radius: 4px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(61, 74, 90);
    border-radius: 4px;
  }
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(76, 77, 92);
    cursor: pointer;
  }
  .session-products-new-pastSession {
    overflow-y: scroll;
    width: calc(100% - 420px);
    position: relative;
    display: flex;
    flex-direction: column;
    height: 100%;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5.208vw;
      color: rgba($headings-color, 0.8);
      font-size: 1.111vw;
      i {
        font-size: 1.389vw;
        margin-right: 0.694vw;
        .rtl & {
          margin-right: unset;
          margin-left: 0.694vw;
        }
      }
    }

    //////////////////////////////////////////
    // Products
    //////////////////////////////////////////
    .products-wrapper {
      .products {
        .product-item {
          flex: 0 0 calc(25% - 3.694vw);
          max-width: 230px;
          min-width: 206px;
          min-height: 250px;
          max-height: 421px;
        }
      }
    }

    //////////////////////////////////////////
    // Analytics
    //////////////////////////////////////////
    .stats-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .stat-item {
        flex: 0 1 calc(100% / 3);
        margin-bottom: 2.083vw;
        padding-right: 1.389vw;

        font-weight: 700;
        font-size: 1.389vw;
        line-height: 1.5;

        .rtl & {
          padding-right: unset;
          padding-left: 1.389vw;
        }

        .stat-value {
          color: var(--primary-store);
        }
      }
    }
  }
  .session-products {
    display: flex;
    width: calc(100% - 415px);
    flex-direction: column;
    padding: 0 1.667vw 1.806vw;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 5.208vw;
      color: rgba($headings-color, 0.8);
      font-size: 1.111vw;
      i {
        font-size: 1.389vw;
        margin-right: 0.694vw;
        .rtl & {
          margin-right: unset;
          margin-left: 0.694vw;
        }
      }
    }

    //////////////////////////////////////////
    // Products
    //////////////////////////////////////////
    .products-wrapper {
      .products {
        .product-item {
          flex: 0 0 calc(25% - 0.694vw);
        }
      }
    }

    //////////////////////////////////////////
    // Analytics
    //////////////////////////////////////////
    .stats-container {
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .stat-item {
        flex: 0 1 calc(100% / 3);
        margin-bottom: 2.083vw;
        padding-right: 1.389vw;

        font-weight: 700;
        font-size: 1.389vw;
        line-height: 1.5;

        .rtl & {
          padding-right: unset;
          padding-left: 1.389vw;
        }

        .stat-value {
          color: var(--primary-store);
        }
      }
    }
  }

  //////////////////////////////////////////
  // Product
  //////////////////////////////////////////
  .product-description-popup-new-pastSession {
    position: relative;
    padding: 1.042vw;
    width: 100%;
    border-radius: 1.389vw;

    .rtl & {
      left: unset;
      right: 1.042vw;
    }

    .btn-close {
      position: absolute;
      top: 0.694vw;
      right: 0.694vw;
      z-index: 2;

      .rtl & {
        right: unset;
        left: 0.694vw;
      }

      img {
        width: 2.5vw;
      }
    }

    .title {
      margin-bottom: get-vw-desktop(8px);
      color: #58575a;
      font-size: get-vw-desktop(14px);
      line-height: 134%;
      font-weight: 600;
    }

    .product-customization {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      margin-top: get-vw-desktop(20px);
      margin-bottom: get-vw-desktop(20px);

      .customization-text {
        width: 100%;
        p {
          font-size: get-vw-desktop(11.35px);
        }
      }
      .customization-form {
        width: 100%;
        margin-right: get-vw-desktop(16px);
        .rtl & {
          margin-right: unset;
          margin-left: get-vw-desktop(16px);
        }

        &.customization-form-image {
          .hint-text {
            margin-left: get-vw-desktop(16px);
            .rtl & {
              margin-left: unset;
              margin-right: get-vw-desktop(16px);
            }
          }
          .btn-remove {
            margin-left: get-vw-desktop(16px);
            .rtl & {
              margin-left: unset;
              margin-right: get-vw-desktop(16px);
            }
            svg {
              width: get-vw-desktop(12px);
              height: get-vw-desktop(12px);
            }
          }
        }

        label {
          display: block;
          font-size: get-vw-desktop(14px);
        }

        .customization-label-input {
          height: get-vw-desktop(35px);
          font-size: get-vw-desktop(14px);
          font-weight: 500;
        }

        .hint-text {
          font-size: get-vw-desktop(10px);
        }
      }
    }

    .img-and-product-details {
      display: flex;
      flex-direction: column;
      gap: 15px;
      @include media-breakpoint-up(lg) {
        flex-direction: row;
        gap: 0;
      }
      .title-new-pastSession {
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        display: flex;
        align-items: center;
        color: #403871;
        margin-bottom: 8px;
      }
    }
    .product-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;

      .product-image-wrapper-new-pastSession {
        position: relative;
        width: 16vw;
        max-width: 284px;
        height: auto;
        border-radius: 10px;
        .product-image {
          max-height: 36vh;
          width: 100%;
          height: 100%;
          border: 0.347vw solid #fff;
          border-radius: 1.042vw;
          object-fit: contain;
          object-position: center;
          background-color: #fff;
        }
        .sold-out-badge {
          position: absolute;
          top: 0;
          margin: 4px;
          padding: 0.208vw 0.417vw;
          z-index: 3;
          display: flex;
          align-items: center;
          color: #ffffff;
          font-size: 0.556vw;
          font-weight: 700;
          line-height: 120%;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          border-radius: 1.042vw;
          background: #f96544;

          .rtl & {
            right: unset;
            left: 1.042vw;
          }
        }
      }

      .product-details-new-pastSession {
        margin-left: 0;
        display: inline-block;
        width: 100%;
        @include media-breakpoint-up(lg) {
          margin-left: 28px;
        }

        .title {
          color: $headings-color;
          font-size: 1.389vw;
          font-weight: 600;
          line-height: 120%;
        }

        .price-new-pastSession {
          width: 61px;
          height: 19px;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          display: flex;
          align-items: center;
          color: #58575a;
          margin-bottom: 9px;
          .previous-price {
            margin-left: 0.556vw;
            color: #b0b5bf;
            font-weight: 600;
            font-size: 80%;
            text-decoration: line-through;

            .rtl & {
              margin-left: unset;
              margin-right: 0.556vw;
            }
          }
        }

        .model-variants-new-pastSession {
          margin-bottom: 15px;
          .model-variant {
            width: 4.167vw;
            height: 4.167vw;
            border-radius: 0.347vw;
            margin-right: 0.278vw;
            margin-bottom: 0.278vw;
            border: 1px solid #d0d0d0;
            overflow: hidden;

            .rtl & {
              margin-right: unset;
              margin-left: 0.278vw;
            }

            .model-image {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
              background-color: #fff;
            }
          }
        }
        .variant-option {
          width: 40px;
          height: 19px;
          font-weight: 400;
          font-size: 14px;
          line-height: 134%;
          display: flex;
          align-items: center;
          color: #58575a;
        }
        .color-variants-dropdown {
          width: 118.85px;
          height: 35px;
          background: #ffffff;
          border-radius: 5px;
        }
        .variants-new-pastSession {
          width: 226px;
          height: 24px;
          display: flex;
          align-items: center;
          margin-bottom: 1.389vw;
          color: #000;
          font-size: 0.903vw;
          font-weight: 300;

          span {
            flex: 0 0 3.611vw;
          }

          .dropdown-toggle {
            width: 118.85px;
            height: 35px;
            background: #ffffff;
            border-radius: 5px;
          }
        }
      }

      .seperator {
        margin: 0 0 1.042vw 0;
        border: 1px solid #f6f6f6;
      }
      .swiper-upcoming-events {
        height: 156px;
        width: 88%;
        height: 213px;
      }

      .product-description-new-pastSession {
        margin-top: 46px;
        margin-bottom: 1.042vw;
        padding-right: 1.042vw;
        font-size: 0.833vw;
        flex-grow: 1;

        .rtl & {
          padding-right: unset;
          padding-left: 1.042vw;
        }

        .read-more {
          display: flex;
          align-items: center;
          margin-top: 0.417vw;
          font-size: 0.833vw;
          color: #c1c1c1;
          img {
            width: 0.833vw;
          }

          &[aria-expanded='true'] {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }

      .extra-info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 4.167vw;
        a,
        button {
          text-align: left;
          color: #000000;
          font-size: 0.833vw;
          line-height: 142%;

          .rtl & {
            text-align: right;
          }

          img {
            margin-right: 0.694vw;
            width: 1.042vw;
            height: 1.042vw;

            .rtl & {
              margin-right: unset;
              margin-left: 0.694vw;
            }
          }
        }
      }

      .buttons {
        margin-top: 0.694vw;
        width: 100%;
        display: flex;
        align-items: center;

        button {
          margin-right: 1.111vw;

          .rtl & {
            margin-right: unset;
            margin-left: 1.111vw;
          }
        }
      }
    }
  }
  .product-description-popup {
    position: absolute;
    top: 1.042vw;
    left: 1.042vw;
    bottom: 1.042vw;
    z-index: 1;
    padding: 1.042vw;
    width: 360px;
    background-color: #fff;
    border-radius: 1.389vw;
    .ng-scroll-content {
      display: flex;
      flex-direction: column;
    }
    .rtl & {
      left: unset;
      right: 1.042vw;
    }

    .btn-close {
      position: absolute;
      top: 0.694vw;
      right: 0.694vw;
      z-index: 2;

      .rtl & {
        right: unset;
        left: 0.694vw;
      }

      img {
        width: 30px;
      }
    }

    .title {
      margin-bottom: get-vw-desktop(8px);
      color: #58575a;
      font-size: get-vw-desktop(14px);
      line-height: 134%;
      font-weight: 600;
    }

    .product-customization {
      display: flex;
      flex-direction: column;
      flex: 0 0 100%;
      margin-top: get-vw-desktop(20px);
      margin-bottom: get-vw-desktop(20px);

      .customization-text {
        width: 100%;
        p {
          font-size: get-vw-desktop(11.35px);
        }
      }
      .customization-form {
        width: 100%;
        margin-right: get-vw-desktop(16px);
        .rtl & {
          margin-right: unset;
          margin-left: get-vw-desktop(16px);
        }

        &.customization-form-image {
          .hint-text {
            margin-left: get-vw-desktop(16px);
            .rtl & {
              margin-left: unset;
              margin-right: get-vw-desktop(16px);
            }
          }
          .btn-remove {
            margin-left: get-vw-desktop(16px);
            .rtl & {
              margin-left: unset;
              margin-right: get-vw-desktop(16px);
            }
            svg {
              width: get-vw-desktop(12px);
              height: get-vw-desktop(12px);
            }
          }
        }

        label {
          display: block;
          font-size: get-vw-desktop(14px);
        }

        .customization-label-input {
          height: get-vw-desktop(35px);
          font-size: get-vw-desktop(14px);
          font-weight: 500;
        }

        .hint-text {
          font-size: get-vw-desktop(10px);
        }
      }
    }

    .product-wrapper {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .product-image-wrapper {
        position: relative;
        margin-bottom: 1.042vw;
        width: 100%;
        height: 17.361vw;
        .product-image {
          width: 100%;
          height: 100%;
          border: 0.347vw solid #fff;
          border-radius: 1.042vw;
          object-fit: contain;
          object-position: center;
          background-color: #fff;
        }
        .sold-out-badge {
          position: absolute;
          top: 0;
          margin: 4px;
          padding: 0.208vw 0.417vw;
          z-index: 3;
          display: flex;
          align-items: center;
          color: #ffffff;
          font-size: 0.556vw;
          font-weight: 700;
          line-height: 120%;
          letter-spacing: 0.04em;
          text-transform: uppercase;
          border-radius: 1.042vw;
          background: #f96544;

          .rtl & {
            right: unset;
            left: 1.042vw;
          }
        }
      }

      .product-details {
        display: inline-block;
        width: 100%;
        margin-top: 16px;
        .title {
          color: $headings-color;
          font-size: 16px;
          font-weight: 600;
          line-height: 120%;
        }

        .price {
          display: flex;
          align-items: center;
          color: #000;
          font-size: 16px;
          line-height: 1;
          font-weight: 600;

          .previous-price {
            margin-left: 0.556vw;
            color: #b0b5bf;
            font-weight: 600;
            font-size: 14px;
            text-decoration: line-through;

            .rtl & {
              margin-left: unset;
              margin-right: 0.556vw;
            }
          }
        }

        .model-variants {
          margin-bottom: 1.042vw;
          .model-variant {
            width: 4.167vw;
            height: 4.167vw;
            border-radius: 0.347vw;
            margin-right: 0.278vw;
            margin-bottom: 0.278vw;
            border: 1px solid #d0d0d0;
            overflow: hidden;

            .rtl & {
              margin-right: unset;
              margin-left: 0.278vw;
            }

            .model-image {
              width: 100%;
              height: 100%;
              object-fit: contain;
              object-position: center;
              background-color: #fff;
            }
          }
        }

        .variants {
          display: flex;
          margin-bottom: 1.389vw;
          color: #000;
          font-weight: 300;
          flex-wrap: wrap;
          row-gap: 15px;
          flex-direction: column;
          span {
            flex: 0 0 3.611vw;
          }

          .dropdown-toggle {
            min-width: 8vw;
            background-color: #f3f3f3;
            font-size: 14px;
            padding: 0 32px 0 10px;
            height: 35px;
          }
        }
      }

      .seperator {
        margin: 0 0 1.042vw 0;
        border: 1px solid #f6f6f6;
      }

      .product-description {
        margin-bottom: 1.042vw;
        padding-right: 1.042vw;
        font-size: 14px;
        flex-grow: 1;

        .rtl & {
          padding-right: unset;
          padding-left: 1.042vw;
        }

        .read-more {
          display: flex;
          align-items: center;
          margin-top: 0.417vw;
          font-size: 0.833vw;
          color: #c1c1c1;
          img {
            width: 0.833vw;
          }

          &[aria-expanded='true'] {
            img {
              transform: rotate(180deg);
            }
          }
        }
      }

      .extra-info {
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        height: 4.167vw;
        a,
        button {
          text-align: left;
          color: #000000;
          font-size: 0.833vw;
          line-height: 142%;

          .rtl & {
            text-align: right;
          }

          img {
            margin-right: 0.694vw;
            width: 1.042vw;
            height: 1.042vw;

            .rtl & {
              margin-right: unset;
              margin-left: 0.694vw;
            }
          }
        }
      }

      .buttons {
        margin-top: 0.694vw;
        width: 100%;
        display: flex;
        align-items: center;

        button {
          margin-right: 1.111vw;

          .rtl & {
            margin-right: unset;
            margin-left: 1.111vw;
          }
        }
      }
    }
  }

  //////////////////////////////////////////
  // Prograss bar
  //////////////////////////////////////////
  .progress-bar {
    position: relative;
    flex: 0 0 5.417vw;
    background-color: #696267;
    justify-self: flex-end;

    &.full {
      background: linear-gradient(180deg, var(--secondary-store) 0%, var(--primary-store) 100%);
    }

    .terrific-icon {
      position: absolute;
      top: 1.111vw;
      left: 0;
      right: 0;
      margin: 0 auto;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 3.125vw;
      height: 3.125vw;
      background: #ffffff;
      box-shadow: 0 0.556vw 0.694vw rgba(184, 107, 249, 0.2);
      border-radius: 50%;

      img {
        padding: 2px;
      }
    }

    .discount-coupon {
      margin: 0 auto;

      .value {
        font-weight: 600;
        font-size: 1.042vw;
        line-height: 1;
        color: var(--secondary-store);
      }
    }
  }
}
.space-above-title {
  margin-top: 30px;
}
.swiperUpcoming {
  padding-bottom: 28px;
}
.calendar {
  width: 100%;
  @media screen and (max-width: 1225px) {
    margin: 12px 0 12px 0;
  }
}
.default-calendar-button {
  display: flex;
  width: 100%;
  height: 42px;
  font-weight: 600;
  color: var(--light-store);
  text-transform: uppercase;
  justify-content: center;
  background-color: var(--primary-store);
  border-radius: 54px;
  align-items: center;
}
.upcoming-desktop {
  display: grid;
  grid-template-columns: 360px auto;
  width: 100%;

  & > * {
    min-width: 0;
  }
}
