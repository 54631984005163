//////////////////////////////////////////
// Dropdown
//////////////////////////////////////////
.dropdown-toggle:not(.form-control, .user-dropdown-button) {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 32px 0 10px;
  height: 35px;
  font-size: 15px;
  font-weight: 700;
  background-color: #f3f3f3;
  color: $body-color;
  border-radius: 5px;

  @include media-breakpoint-up(xl) {
    padding: 0 1.917vw 0 0.625vw;
    height: 2.431vw;
    font-size: 1.042vw;
    border-radius: 0.347vw;
    background-size: 1.2vw;
    background-color: #ffffff;
  }

  &::after {
    position: absolute;
    content: '';
    display: block;
    top: 50%;
    right: 0;
    width: 26px;
    height: 26px;
    background-image: url('/assets/images/icons/dropdown-arrow-icon.svg');
    background-repeat: no-repeat;
    background-size: 26px;
    background-position: center;
    transform: translate(-15%, -50%);

    @include media-breakpoint-up(xl) {
      width: 1.389vw;
      height: 1.389vw;
      background-size: 1.389vw;
    }
  }

  &[aria-expanded='true']::after {
    transform: translate(-15%, -50%) scaleY(-1);
  }

  .rtl & {
    padding: 0 10px 0 32px;
    @include media-breakpoint-up(xl) {
      padding: 0 0.625vw 0 1.917vw;
    }

    &::after {
      right: unset;
      left: 0;
      transform: translate(15%, -50%);
    }

    &[aria-expanded='true']::after {
      transform: translate(15%, -50%) scaleY(-1);
    }
  }

  &.disabled,
  &:disabled {
    color: #9aa4ad4d;
    cursor: default;
    &::after {
      opacity: 0.3;
    }
  }

  &.out-of-stock {
    color: #9aa4ad4d;
    &::after {
      opacity: 0.3;
    }
  }
}

.dropdown-menu {
  min-width: 100%;
  border-color: #fff;
  box-shadow: 0px 4px 20px rgba(#030c23, 0.1);
  border-radius: 10px;
  @include media-breakpoint-up(xl) {
    border-radius: 0.347vw;
  }
  .dropdown-item {
    padding: 10px 24px;
    border-radius: 10px;
    @include media-breakpoint-up(xl) {
      border-radius: 0.347vw;
    }
    &:hover {
      color: #fff;
      background-color: #000;
    }

    &.disabled,
    &:disabled {
      color: #9aa4ad4d;
      cursor: default;
    }

    &.out-of-stock {
      color: #9aa4ad4d;
    }
  }
}

.rtl .dropdown-menu,
[dir='rtl'] .dropdown-menu {
  left: inherit !important;
}
