// Shop logo
.stores-section .logo, // Homepage: store logo
.brand-logo-image, // Store page: store logo
.btn-brand img, // Session page: store logo
.promo-brand-logo-container img, // Session page: promo store logo
.swiper-upcoming-events .cover-img, // Passed session: upcoming session shop logo
// Session image
// .product-cards-wrapper .product-image, // Store page: session image
// .organizer-picture img, // Upcoming session: session image
// .swiper-upcoming-events .upcoming-img, // Passed session: upcoming session
// Product
.btn-view-item img, // Session page: view product (on live stream)
.goal-discount-product img, // Session page: current goal product (on discount bar)
.products-wrapper .product-image, // Session page: product
.promo-products img, // Session page: promo product
.product-wrapper .product-image, // Session page: focused product
.model-image, // Session page: focused product variant
.poll__answer-image img, // Session page: poll items
app-analytics-host .item-img, // Session page: analytics product
.product-option__image img // Admin section: items in session
{
  &[src=''] {
    position: relative;
    display: inline-block;
    z-index: 0;

    &:after {
      position: absolute;
      content: '';
      background: center #ebecec url('/assets/images/no-image.svg') no-repeat;
      background-size: 30%;
      bottom: 0;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1;
    }
  }
}

// Shop logo
.stores-section .logo, // Homepage: store logo
.brand-logo-image, // Store page: store logo
.btn-brand img, // Session page: store logo
.promo-brand-logo-container img, // Session page: promo store logo
.swiper-upcoming-events .cover-img // Passed session: upcoming session shop logo
{
  &[src='']:after {
    background: center #fff url('/assets/images/defaultStoreLogo.svg') no-repeat;
    background-size: 35%;
  }
}

.brand-logo-image[src=''] {
  // Store page: store logo
  // Transparent background
  visibility: hidden;

  &:after {
    background-color: transparent;
    visibility: visible;
  }
}

.product-wrapper .product-image[src=''] {
  @include media-breakpoint-up(xl) {
    // Desktop
    min-height: 175px;
  }
}
