//////////////////////////////////////////
// Products Cards
//////////////////////////////////////////
.product-cards {
  .container-xl {
    max-width: 1225px;
  }

  .product-cards-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    @include media-breakpoint-up(md) {
      margin: 0 -10px;
    }
    @include media-breakpoint-up(xl) {
      margin: 0 -32.5px;
    }
  }

  .product-card {
    display: flex;
    flex-direction: column;
    margin: 0 10px 44px;
    flex: 0 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
    @include media-breakpoint-up(md) {
      margin: 0 32.5px 44px;
      flex: 0 1 calc(50% - 65px);
      max-width: calc(50% - 65px);
    }
    @include media-breakpoint-up(xl) {
      max-width: 340px;
      flex: 0 1 340px;
      margin: 0 32.5px 44px;
    }

    .product-box {
      position: relative;
      display: block;
      width: 100%;
      height: 86.133vw;
      border-radius: 15px;
      overflow: hidden;
      transition: box-shadow 150ms ease-out;
      box-shadow: none;
      text-decoration: none;
      will-change: transform;

      @include media-breakpoint-up(md) {
        height: 48.98vw;
      }
      @include media-breakpoint-up(xl) {
        height: 408px;
      }

      &:hover {
        box-shadow: 0 0 20px 0 rgba($primary, 0.5);
        .buttons {
          visibility: visible;
          opacity: 1;
          @include media-breakpoint-up(md) {
            width: calc(100% - 10px);
            margin: auto;
          }
        }
        .product-image {
          transform: scale(1.05);
        }
      }

      &.show-content {
        box-shadow: 0 0 20px 0 rgba($primary, 0.5);
        .buttons {
          visibility: visible;
          opacity: 1;
        }
        .product-image {
          transform: scale(1.05);
        }
      }

      .product-image {
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
        transition: transform 150ms ease-out;
        transform: scale(1);
      }

      .badge {
        position: absolute;
        top: 4px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2px 5px;
        color: #fff;
        font-weight: 500;
        font-size: 3.6vw;
        line-height: 1.4;
        background: rgba(#000, 0.4);
        border-radius: 12.5px;
        z-index: 4;
        letter-spacing: 0;
        @include media-breakpoint-up(md) {
          top: 10px;
          left: 10px;
          padding: 7px 10px;
          font-size: 18px;
          line-height: 22px;
          border-radius: 18px;
        }
        @include media-breakpoint-up(xl) {
          font-size: 18px;
        }

        .rtl & {
          left: unset;
          right: 5px;
          @include media-breakpoint-up(md) {
            left: unset;
            right: 10px;
          }
        }

        .icon {
          font-size: 18px;
          @include media-breakpoint-up(xl) {
            margin-right: 5px;
            font-size: 22.5px;
          }

          .rtl & {
            @include media-breakpoint-up(xl) {
              margin-left: unset;
              margin-right: 5px;
            }
          }
        }

        span {
          padding: 0 5px;
        }

        .time {
          text-align: left;
          @include media-breakpoint-up(xl) {
            width: 70px;
          }
          .rtl & {
            text-align: right;
          }
        }

        .date {
          opacity: 0.75;
        }

        &.countdown {
          right: 5px;
          flex-wrap: wrap;
          width: auto;
          font-size: 3vw;
          letter-spacing: -0.03em;

          @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            width: auto;
            right: 70px;
            font-size: 18px;
          }

          .rtl & {
            right: unset;
            left: 5px;
            @include media-breakpoint-up(md) {
              right: unset;
              left: 70px;
            }
          }

          span {
            padding: 0 2px;
          }
          .date {
            flex-grow: 1;
          }

          .time {
            flex-grow: 1;
          }

          .icon {
            font-size: 3.2vw;
            @include media-breakpoint-up(xl) {
              font-size: 22.5px;
            }
          }
        }

        &.done {
          padding: 8px 10px 6px;
          flex-wrap: wrap;
          width: 74%;

          @include media-breakpoint-up(md) {
            width: auto;
            flex-wrap: nowrap;
          }

          .date {
            flex-grow: 1;
          }
        }

        &.live {
          &::before {
            content: '•';
            margin-top: -3px;
            margin-right: 5px;
            font-weight: 600;
            font-size: 31.5px;
            line-height: 22px;
            display: flex;
            align-items: center;
            color: #ff4b33;

            .rtl & {
              margin-right: unset;
              margin-left: 5px;
            }
          }
        }
      }

      .coupon-tag {
        position: absolute;
        top: 5px;
        right: 5px;
        font-weight: 600;
        font-size: 16px;
        color: var(--light-store);

        @include media-breakpoint-up(md) {
          top: 10px;
          right: 10px;
        }

        .rtl & {
          right: unset;
          left: 5px;
          @include media-breakpoint-up(md) {
            right: unset;
            left: 10px;
          }
        }

        &.coupon-tag-countdown {
          @include media-breakpoint-down(md) {
            top: 44px;
          }
        }
      }

      .buttons {
        position: absolute;
        display: flex;
        flex-direction: column;
        padding: 0 5px;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
        transition: opacity 300ms ease-out;

        @include media-breakpoint-up(md) {
          padding: 0 10px 10px;
          flex-direction: row;
        }
        @include media-breakpoint-up(xl) {
          visibility: hidden;
          opacity: 0;
          flex-direction: row;
          padding: 0 5px 10px;
        }
        app-share-popup {
          display: flex;
          @include media-breakpoint-up(md) {
            flex: 0 1 50%;
          }

          .btn-base:not(.circle) {
            flex-grow: 1;
            max-width: 94%;
          }
        }

        .btn-base:not(.circle) {
          margin: 0 5px 7px;

          @include media-breakpoint-down(sm) {
            font-size: 14px;
          }

          @include media-breakpoint-up(md) {
            flex: 1 1 50%;
            margin: 0;
            padding: 0 10px;
          }

          .icon {
            @include media-breakpoint-down(sm) {
              margin-right: 0px;
              margin-left: 0px;
            }
            @include media-breakpoint-down(md) {
              margin-right: 0px;
              margin-left: 0px;
            }

            .rtl & {
              @include media-breakpoint-down(sm) {
                margin-right: unset;
                margin-left: 8px;
              }
            }
          }
        }

        .btn-remind-me {
          img.icon {
            width: 24px;
            height: 24px;
          }

          &.active {
            background: #b0b5bf;
          }
        }
      }

      .session-is-full {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: rgba(#fff, 0.7);
        backdrop-filter: blur(20px);
        z-index: 3;

        img {
          margin-top: 18px;
          margin-bottom: 30px;
          width: 136px;
          height: 136px;
          @include media-breakpoint-up(xl) {
            width: 218px;
            height: 218px;
          }
        }

        p {
          padding: 0 10px;
          font-weight: 600;
          font-size: 16px;
          text-align: center;
          color: #200e32;
          @include media-breakpoint-up(xl) {
            padding: 0 30px;
            font-size: 16px;
          }
        }
      }
    }

    .user {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 10px 0;

      @include media-breakpoint-down(lg) {
        justify-content: flex-start;
      }
    }

    .user-avatar {
      margin-right: 8px;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 1;
      .rtl & {
        margin-right: unset;
        margin-left: 8px;
      }
    }

    .user-name {
      color: #58575a;
      font-style: normal;
      font-weight: 600;
      font-size: 12px;
      line-height: 1;
      flex-grow: 1;
      max-width: calc(100% - 64px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      @include media-breakpoint-down(lg) {
        max-width: calc(100% - 32px);
        font-size: 14px;
      }
    }

    .description {
      margin-bottom: 8px;
      color: #9e9ea9;
      font-size: 12px;
      line-height: 130%;
      letter-spacing: -0.03em;
      word-wrap: break-word;
      padding: 0 4px;

      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
        font-size: 15px;
      }
    }

    .likes {
      display: flex;
      align-items: center;
      font-size: 10.5px;
      color: #58575a;
      @include media-breakpoint-up(xl) {
        margin-top: 2px;
        font-size: 14px;
      }

      .icon {
        width: 14px;
        margin-right: 10px;
        @include media-breakpoint-up(xl) {
          margin-right: unset;
          margin-left: 10px;
          width: 18px;
        }
        .rtl & {
          margin-right: unset;
          margin-left: 10px;
          @include media-breakpoint-up(xl) {
            margin-right: unset;
            margin-left: 10px;
          }
        }
      }
    }
  }
}
