//////////////////////////////////////////
// Hero Section
//////////////////////////////////////////
section.hero-section {
  position: relative;
  padding: 4.8vw 10px 18.667vw;
  overflow: hidden;
  z-index: 1;
  background-color: #000;
  @include media-breakpoint-up(md) {
    padding: 10vw 20px 0;
    height: 70.468vw;
  }
  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: center;
    padding-top: 0;
    padding-right: 0;
    padding-left: 2.917vw;
    height: 42.222vw;
  }

  .rtl & {
    @include media-breakpoint-up(xl) {
      padding-left: 0;
      padding-right: 2.917vw;
    }
  }

  .hero-image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
    pointer-events: none;
    z-index: -1;
    &.mobile {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
    &.tablet {
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
      @include media-breakpoint-up(xl) {
        display: none;
      }
    }
    &.desktop {
      display: none;
      @include media-breakpoint-up(xl) {
        display: block;
      }
    }
  }

  .hero-video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    object-fit: cover;
  }

  .brand-logo-container {
    margin: 0 auto 4.8vw auto;
    width: 45.867vw;
    height: 45.867vw;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url('/assets/images/hero-logo-holder.svg');
    background-size: 100%;
    background-position: left bottom;
    background-repeat: no-repeat;
    z-index: 1;
    @include media-breakpoint-up(md) {
      margin: 0;
      position: absolute;
      top: -8vw;
      right: -6vw;
      width: 43.818vw;
      height: 43.818vw;
    }

    @include media-breakpoint-up(xl) {
      top: -7.083vw;
      right: -3.056vw;
      width: 27.5vw;
      height: 27.5vw;
    }

    .rtl & {
      @include media-breakpoint-up(md) {
        right: unset;
        left: -6vw;
      }
      @include media-breakpoint-up(xl) {
        right: unset;
        left: -3.056vw;
      }
    }

    .brand-logo-image {
      width: 75%;
      height: 65%;
      object-fit: contain;
      object-position: center;

      @include media-breakpoint-up(xl) {
        margin-top: 5vw;
      }
    }
  }

  .hero-title {
    margin-bottom: 0;
    font-style: normal;
    font-weight: 900;
    font-size: 10.667vw;
    line-height: 11.467vw;
    text-transform: uppercase;
    color: #ffffff;
    text-shadow: 0px 0px 17px rgba(0, 0, 0, 0.35);
    @include media-breakpoint-up(md) {
      width: 58vw;
      font-size: 5.882vw;
      line-height: 6.363vw;
    }
    @include media-breakpoint-up(xl) {
      width: 34.333vw;
      font-size: 3.681vw;
      line-height: 3.958vw;
    }
  }

  .avatars {
    display: flex;
    align-items: center;
    margin-top: 22px;
    margin-left: 12px;
    @include media-breakpoint-up(xl) {
      margin-top: 40px;
      margin-left: 12px;
    }

    .rtl & {
      margin-left: unset;
      margin-right: 12px;
      @include media-breakpoint-up(xl) {
        margin-left: unset;
        margin-right: 12px;
      }
    }
    .user-avatar {
      width: 32px;
      height: 32px;
      border: 1px solid #fff;
      border-radius: 50%;
      margin-left: -12px;
      overflow: hidden;

      .rtl & {
        margin-left: unset;
        margin-right: -12px;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        object-position: center;
      }
    }

    .user-avatar-counter {
      display: flex;
      align-items: center;
      margin-left: -12px;
      padding: 0 0.75vw;
      height: 32px;
      text-align: center;
      color: #f6f7fb;
      font-weight: bold;
      font-size: 15px;
      line-height: 22px;
      background: #ff53a1;
      border: 1px solid #fff;
      border-radius: 16px;
      .rtl & {
        margin-left: unset;
        margin-right: -12px;
      }
    }
  }

  //////////////////////////////////////////
  // Buttons
  //////////////////////////////////////////
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 50px 30px 0;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      justify-content: flex-start;
      margin: 68px 0 0 0;
    }

    .btn-base {
      width: 100%;
      @include media-breakpoint-up(md) {
        margin-right: 12px;
        width: auto;
      }

      .rtl & {
        @include media-breakpoint-up(md) {
          margin-right: unset;
          margin-left: 12px;
        }
      }
    }

    .btn-signup {
      margin-top: 15px;
      @include media-breakpoint-up(md) {
        margin-top: 0;
        width: 175px;
      }
    }

    .sign-up-text {
      margin-top: 18px;
      font-size: 13px;
      text-align: center;
      color: #fff;
      .btn-sign-up {
        color: #fff;
        text-decoration: underline;
      }
    }
  }
}

//////////////////////////////////////////
// Alert
//////////////////////////////////////////
.store-homepage-loader {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}
.unpublished-store-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  color: #721c24;
  background-color: #f8d7da;
  border-color: #f5c6cb;
}
