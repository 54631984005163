//////////////////////////////////////////
// Products
//////////////////////////////////////////
.session-screen-mobile {
  .products-wrapper {
    .products {
      position: relative;
      padding: 0 10px;
      width: 100%;
      z-index: 1;

      .product-item {
        position: relative;
        display: flex;
        flex-direction: column;
        margin: 0 10px;
        margin-bottom: 20px;
        padding: 7px;
        flex: 0 0 calc(50% - 20px);
        border-radius: 10px;
        border: 0;
        background-color: #fff;
        transition: all 300ms ease-out;

        &.selected {
          border-color: $pink;
        }

        &.unavailable {
          cursor: default;
          .item-picture {
            position: relative;
            &::after {
              position: absolute;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
              content: '';
              background-image: url('/assets/images/lock.svg');
              background-position: center;
              background-repeat: no-repeat;
              pointer-events: none;
              background-size: 20%;
              z-index: 1;
            }
            .product-image {
              object-fit: contain;
            }
          }
          .views-needed {
            flex-grow: 1;
            font-size: 13px;
            text-align: center;
            i {
              margin-right: 6px;
              font-size: 13px;

              .rtl & {
                margin-right: unset;
                margin-left: 6px;
              }
            }
          }
        }

        .item-picture {
          position: relative;
          margin: 0 auto 10px;
          width: 100%;
          border-radius: 5px;
          overflow: hidden;

          &::before {
            display: block;
            content: '';
            padding-top: 100%;
          }

          .product-image {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
            object-position: center;
          }

          .brand-logo {
            position: absolute;
            top: 1.6vw;
            left: 1.6vw;
            right: 0;
            bottom: 0;
            width: 8.533vw;
            height: 8.533vw;
            object-fit: contain;
            object-position: center;
            z-index: 2;
          }

          .bullets {
            position: absolute;
            top: -0.417vw;
            right: -0.417vw;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 0.347vw;
            background: #eeeeee;
            border-radius: 0.556vw;
            z-index: 3;
            .rtl & {
              right: unset;
              left: -0.417vw;
            }
          }

          .btn-delete {
            position: absolute;
            top: 0;
            right: 0;
            justify-content: center;
            align-items: center;
            width: 32px;
            height: 32px;
            background-color: #838383;
            border-radius: 50%;
            z-index: 2;

            .rtl & {
              right: unset;
              left: 0;
            }

            img {
              width: 17px;
            }
          }
          .sold-out-badge {
            position: absolute;
            top: 0;
            margin: 4px;
            padding: 0.208vw 0.417vw;
            z-index: 3;
            display: flex;
            align-items: center;
            color: #ffffff;
            font-size: 12px;
            font-weight: 700;
            line-height: 120%;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            border-radius: 1.042vw;
            background: #f96544;

            .rtl & {
              right: unset;
              left: 0.694vw;
            }
          }
        }

        .item-title {
          margin-bottom: 7px;
          font-size: 15px;
          line-height: 18px;
          @extend .line-clamp-2;
        }

        .item-price {
          margin-top: auto;
          margin-bottom: 0;
          color: var(--secondary-store);
          font-style: normal;
          font-weight: 600;
          font-size: 17px;

          .previous-price {
            color: #5e6978;
            font-size: 15px;
            font-weight: normal;
            text-decoration: line-through;
          }
        }
        .quick-add-to-card {
          position: absolute;
          padding: 6.5px;
          width: 34px;
          right: 5px;
          bottom: 5px;
          height: 34px;
          background: var(--primary-store);
          border: 1px solid #ffffff;
          box-shadow: 0px 2px 4px rgba(40, 41, 61, 0.04), 0px 8px 16px rgba(96, 97, 112, 0.11);
          border-radius: 60px;
        }

        .items-sold {
          position: absolute;
          top: -10px;
          right: -10px;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          color: #fff;
          font-weight: 600;
          font-size: 18px;
          letter-spacing: -0.03em;
          background-color: #ff647c;
          border-radius: 50%;
          .rtl & {
            right: unset;
            left: -10px;
          }
        }
      }
    }
  }
}
