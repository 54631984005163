//////////////////////////////////////////
// Login
//////////////////////////////////////////
.modal.login-modal {
  .modal-dialog {
    margin: 0;
    background-color: #f6f7fb;
    max-width: 100%;
    min-height: 100%;
    align-items: stretch;
    @include media-breakpoint-up(md) {
      background-color: transparent;
      align-items: center;
    }
    .modal-content {
      margin: 0 auto;
      width: 100%;
      background: transparent;
      border: 0;
      box-shadow: none;
      backdrop-filter: none;

      .container-xl {
        position: relative;
        display: flex;
        height: 100%;
        flex-direction: column;
        padding: 0;

        @include media-breakpoint-up(md) {
          flex-direction: row;
          justify-content: center;
          align-items: center;
          width: 530px;
        }

        @include media-breakpoint-up(xl) {
          width: 1170px;
        }

        .desktop-logo-container {
          display: none;
          @include media-breakpoint-up(xl) {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 0 0 633px;
            height: 633px;
            background-color: #ef60ab;
            background-image: url('/assets/images/login-desktop-side-bg.svg');
            background-size: auto 100%;
            background-position: left center;
            background-repeat: no-repeat;
            border-radius: 16px 0 0 16px;
          }

          .rtl & {
            @include media-breakpoint-up(xl) {
              border-radius: 0 16px 16px 0;
            }
          }

          .brand-logo-container {
            position: absolute;
            bottom: 100px;
            left: 60px;
            width: 256px;
            z-index: 1;
            .rtl & {
              left: unset;
              right: 60px;
            }
            .logo {
              width: 100%;
            }
          }

          .powered-by-terrific {
            position: absolute;
            bottom: 45px;
            left: 60px;
            z-index: 1;
            .rtl & {
              left: unset;
              right: 60px;
            }
          }
        }

        .content {
          position: relative;
          display: flex;
          flex-grow: 1;
          flex-direction: column;
          min-height: calc(var(--vh, 1vh) * 100);
          @include media-breakpoint-up(md) {
            padding: 28px 0;
            box-shadow: 0px 20px 100px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05);
            border-radius: 14px;
            background-color: #fff;
          }
          @include media-breakpoint-up(xl) {
            padding: 66px 0 0;
            flex: 1 1 100%;
            min-height: 790px;
          }

          //////////////////////////////////////////
          // Narrow wrapper
          //////////////////////////////////////////
          .narrow-wrapper {
            position: relative;
            display: flex;
            flex-grow: 1;
            flex-direction: column;
            padding: 0 20px;

            @include media-breakpoint-up(md) {
              padding: 0 90px;
            }
          }

          //////////////////////////////////////////
          // wide warpper
          //////////////////////////////////////////
          .wide-wrapper {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 0;

            @include media-breakpoint-up(md) {
              padding: 0 40px;
            }

            @include media-breakpoint-up(xl) {
              padding: 0 46px;
            }
          }
        }

        //////////////////////////////////////////
        // Form
        //////////////////////////////////////////
        .form-input-wrapper {
          display: flex;
          flex-direction: column;
          flex-grow: 1;
          width: 100%;

          &.phone-input-wrapper {
            @include media-breakpoint-up(xl) {
              margin-top: 42px;
            }
          }

          .form-group {
            position: relative;
            margin-bottom: 10px;
            .password-visibility {
              display: block;
              position: absolute;
              right: 2px;
              top: 8px;
              .rtl & {
                right: unset;
                left: 2px;
              }
            }
          }

          .form-control {
            color: #000;
            border: 0;
            border-bottom: 1px solid #949ba5;
            border-radius: 0;
            background-color: transparent;
            outline: 0;
            box-shadow: none !important;

            &:focus {
              border-color: var(--primary-store);
            }
            &:focus:not([type='password']) {
              font-weight: 600;
            }

            &::placeholder {
              color: #200e32;
            }

            &.ng-invalid.ng-touched {
              position: relative;
              border-color: $danger;
              padding-right: 30px;
              background-image: url('/assets/images/icons/input-invalid-icon.svg');
              background-size: 20px;
              background-position: calc(100% - 2px) center;
              background-repeat: no-repeat;
              .rtl & {
                padding-right: unset;
                padding-left: 30px;
                background-position: calc(0% + 2px) center;
              }
            }

            &.form-control-password {
              padding-right: 28px;

              .rtl & {
                padding-right: unset;
                padding-left: 28px;
              }

              &.ng-invalid.ng-touched {
                padding-right: 50px;

                .rtl & {
                  padding-right: unset;
                  padding-left: 50px;
                }
              }

              &.ng-invalid.ng-touched + .password-visibility {
                right: 28px;

                .rtl & {
                  right: unset;
                  left: 28px;
                }
              }
            }
          }

          .phone-prefix {
            display: flex;
            align-items: center;
            color: #b0b5bf;
            text-align: left;
            padding-left: 0;
            background-image: none;

            .rtl & {
              text-align: right;
            }

            &.ng-invalid.ng-touched {
              .ng-select-container {
                background-color: transparent;
                border: 0;
                border-bottom: 1px solid $danger;
              }
            }

            .ng-select-container {
              border-radius: 0;
              border-bottom: 1px solid #949ba5;
              outline: 0;
            }

            .flag {
              height: 22px;
              vertical-align: sub;
            }
          }

          .dropdown-menu {
            height: 180px;
            .dropdown-item {
              padding: 10px;
            }
          }

          .invalid-feedback {
            display: block;
            color: $danger;
            font-size: 14px;
            line-height: 20px;
          }

          .forgot-password-link {
            display: block;
            margin-top: 5px;
            margin-left: auto;
            width: fit-content;
            color: #000000;
            font-size: 13px;
            line-height: 19px;
            text-align: right;
            text-decoration-line: underline;
            cursor: pointer;

            .rtl & {
              margin-left: unset;
              margin-right: auto;
              text-align: left;
            }
          }

          .btn-submit {
            display: block;
            margin-top: 15px;
            width: 100%;
            font-weight: 600;
            font-size: 21px;
          }

          .note {
            width: 100%;
            margin-top: 10px;
            font-size: 4.2vw;
            line-height: 25px;
            color: #121a69;
            text-align: center;
            @include media-breakpoint-up(md) {
              font-size: 17px;
            }

            .link {
              border: 0;
              color: inherit;
              background-color: transparent;
              font-weight: 600;
            }
          }
        }

        //////////////////////////////////////////
        // Tabs
        //////////////////////////////////////////
        .nav {
          margin-top: 34px;
          margin-bottom: 30px;
          justify-content: center;
          @include media-breakpoint-down(sm) {
            justify-content: center;
          }

          .nav-item {
            margin: 0 20px;

            .nav-link {
              padding: 0 0 2px 0;
              color: #9e9ea9;
              font-weight: 600;
              font-size: 15px;
              line-height: 20px;
              border-bottom: 2px solid transparent;

              &.active {
                color: #58575a;
                border-bottom: 2px solid var(--secondary-store);
              }
            }
          }
        }

        .tab-content {
          display: flex;
          flex-direction: column;
          flex-grow: 1;

          .tab-pane {
            height: 100%;
            .tab-inner {
              display: flex;
              flex-direction: column;
              flex-grow: 1;
              height: 100%;
            }

            .text-seperator {
              margin-top: 30px;
              margin-bottom: 30px;
              display: flex;
              justify-content: space-between;
              align-items: center;
              .seperator {
                flex: 1 1 100%;
                display: block;
                height: 1px;
                background-color: #b0b5bf;
              }

              .text {
                margin: 0 10px;
                flex: 1 1 100%;
                color: #b0b5bf;
                text-align: center;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                white-space: nowrap;
              }
            }
          }
        }

        //////////////////////////////////////////
        // Elements
        //////////////////////////////////////////
        .back-button {
          display: inline-flex;
          align-self: flex-start;
          margin: 0 0 15px 0;
          padding: 0 10px;
          width: auto;
          font-size: 12px;
          font-weight: 700;
          line-height: 18px;
          text-align: left;
          text-decoration: none;
          text-transform: uppercase;
          color: #58575a;
          background-color: transparent;

          @include media-breakpoint-up(xl) {
            position: absolute;
            top: 15px;
            left: 32px;
            background-color: #fff;
            border-radius: 15px;
            .rtl & {
              left: unset;
              right: 32px;
            }
          }

          i {
            display: inline-block;
            font-size: 19px;
            margin-right: 5px;
            vertical-align: bottom;
            .rtl & {
              margin-right: unset;
              margin-left: 5px;
              transform: scaleX(-1);
            }
          }
        }

        .big-icon-circle {
          background-color: var(--secondary-store);
          border-radius: 50%;
          color: white;
          padding: 20px;
          margin: 40px auto;
        }

        .title {
          color: $headings-color;
          font-style: normal;
          font-weight: 700;
          font-size: 6.133vw;
          line-height: 7.733vw;
          text-align: center;
          letter-spacing: -0.02em;
          @include media-breakpoint-up(md) {
            margin-bottom: 12px;
            font-size: 30px;
            line-height: 1.1;
            text-align: center;
          }
          @include media-breakpoint-up(xl) {
            font-size: 36px;
          }
          .pink {
            color: #ef60ab;
          }
        }

        .help-text-v2 {
          font-weight: 600;
          font-size: 3.733vw;
          color: #9e9ea9;

          @include media-breakpoint-up(md) {
            font-size: 20px;
          }
          @include media-breakpoint-up(xl) {
            font-size: 20px;
          }
        }
        .sub-help-text-v2 {
          color: #9e9ea9;
          font-size: 2.5vw;
          @include media-breakpoint-up(md) {
            font-size: 15px;
          }
        }

        .help-text {
          width: 100%;
          font-size: 3.733vw;
          line-height: 120%;
          color: #949ba5;
          text-align: center;
          @include media-breakpoint-up(md) {
            text-align: center;
            font-size: 20px;
          }
          @include media-breakpoint-up(xl) {
            font-size: 20px;
          }

          .rtl & {
            @include media-breakpoint-up(md) {
              text-align: right;
            }
          }

          &.help-text-password {
            @include media-breakpoint-up(xl) {
              margin-bottom: 95px;
            }
          }
        }

        code-input {
          --item-spacing: 0px;
          --item-height: 2em;
          --item-border: none;
          --item-border-bottom: 1px solid #949ba5;
          --item-border-has-value: none;
          --item-border-bottom-has-value: 1px solid var(--primary-store);
          --item-border-focused: none;
          --item-border-bottom-focused: 1px solid var(--primary-store);
          --item-shadow-focused: none;
          --item-border-radius: 0px;
        }

        .code-input-form {
          margin-top: 8vw;
          margin-bottom: 8vw;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          direction: ltr !important;
          @include media-breakpoint-up(md) {
            margin-top: 50px;
            margin-bottom: 58px;
          }
          input {
            margin: 0 3.2vw;
            width: 10vw;
            text-align: center;
            font-size: 5.867vw;
            border-radius: 0;
            background-color: transparent;
            outline: 0;

            @include media-breakpoint-up(md) {
              margin: 0 12px;
              width: 48px;
              font-size: 26px;
            }
          }

          .invalid-feedback {
            display: block;
            margin-top: 22px;
            color: $danger;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
          }
        }

        .btn-login {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-bottom: 5px;
          padding: 0 10px;
          width: 100%;
          height: 55px;
          color: #333838;
          font-weight: 500;
          font-size: 14px;
          line-height: 120%;
          background: #ffffff;
          border-radius: 5px;
          border: 1px solid #e2e2e2;
          box-shadow: 0px 4px 15px 0 rgba(#000, 0.1);

          .icon {
            margin: 0 10px 0 6px;
            width: 24px;
            height: 24px;
            @include media-breakpoint-up(xl) {
              margin: 0 20px 0 12px;
            }
            .rtl & {
              margin: 0 6px 0 10px;
              @include media-breakpoint-up(xl) {
                margin: 0 12px 0 20px;
              }
            }

            &.sms {
              height: 29px;
              width: 29px;
              margin-inline-start: 10px;
              margin-inline-end: 17px;
            }
          }
          .seperator {
            display: block;
            margin-right: 20px;
            height: 38px;
            border-left: 1px solid #f3f3f3;
            .rtl & {
              margin-right: unset;
              margin-left: 20px;
              border-left: unset;
              border-right: 1px solid #f3f3f3;
            }
          }
        }
      }
    }
  }

  .agreed-policy-container {
    position: relative;
    display: flex;
    align-items: center;

    .legal-text {
      font-size: 0.8rem;
    }
  }

  // this placeholder come to fix position of checkbox because issue with position absolute inside checkbox component
  .agreed-checkbox-placeholder {
    position: relative;
    width: 1.5rem;
    height: 1.5rem;
  }
}
