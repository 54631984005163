.store-users {
  h2 {
    font-weight: 600;
    font-size: 34px;
    margin-bottom: 20px;
  }
  form {
    display: flex;
    .icon-input {
      display: flex;
      position: relative;
      height: 50px;
      img {
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(-50%);
        .rtl & {
          left: 16px;
          right: 16px;
        }
      }
    }
    input[type='email'] {
      width: 450px;
      border-radius: 100px;
      padding-left: 45px;
      height: 50px;
      .rtl & {
        padding-left: unset;
        padding-right: 45px;
      }
    }

    .btn-submit {
      margin-left: 15px;
      padding: 10px 25px;
      height: 50px;
      border-radius: 41px;
      color: #fff;
      font-size: 18px;
      font-weight: 600;
      .rtl & {
        margin-left: unset;
        margin-right: 15px;
      }
    }
  }
  .users-table {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 900px;
    padding: 24px 0;
    margin-top: 8px;
    .users-row {
      display: flex;
      padding: 12px 0;
      & > div {
        display: flex;
        align-items: center;
      }
      & > div:nth-child(1) {
        flex: 0.36;
      }
      & > div:nth-child(2) {
        flex: 0.24;
        color: #b0b5bf;
      }
      & > div:nth-child(3) {
        flex: 0.4;
        display: flex;
        button {
          transition: 0.4s;
          &:hover {
            background-color: #ffc8bb;
          }
        }
      }
    }
    .users-headline {
      font-weight: 400;
      font-size: 11px;
      color: #5e6978;
      div:nth-child(2) {
        color: initial;
      }
    }

    .btn-holder {
      margin-right: 10px;
      width: 170px;
      .rtl & {
        margin-right: unset;
        margin-left: 10px;
      }
    }
  }
}
