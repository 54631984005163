//////////////////////////////////////////
// Tabs
//////////////////////////////////////////
.nav.nav-style-mobile {
  width: 100%;
  flex-wrap: nowrap;
  padding-bottom: 3px;
  overflow-x: auto;
  .nav-item {
    flex: 1 1 100%;
    height: 100%;

    .nav-link {
      position: relative;
      padding: 4px 0;
      padding-right: 15px;
      height: 100%;
      color: #333838;
      font-size: 16px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      border-bottom: 4px solid rgba(#333838, 0.1);
      .rtl & {
        padding-right: 0;
        padding-left: 15px;
      }

      &.active {
        color: var(--secondary-store);
        border-bottom: 4px solid var(--secondary-store);
      }
    }
  }
}
