iframe .toggle-reminder-modal {
  &.mobile {
    overflow: hidden !important;
  }
}
.toggle-reminder-modal {
  display: flex !important;
  .modal-dialog {
    margin: auto;
    .modal-content {
      margin: 0;
    }
  }
}
