.registered-sessions-modal {
  .modal-dialog {
    .modal-content {
      app-registered-sessions-modal {
        //////////////////////////////////////////
        // Session items
        //////////////////////////////////////////
        .sessions {
          .session-item-grid {
            display: grid;
            grid-template-areas:
              'cover details'
              'description description'
              'actions actions';
            grid-gap: 1px;
            grid-template-columns: 150px auto;
            grid-template-rows: auto;
            grid-gap: 10px 20px;
            margin-bottom: 35px;

            @include media-breakpoint-up(xl) {
              margin-bottom: 1.736vw;
              grid-template-areas:
                'cover details'
                'cover description'
                'cover actions';
              grid-template-columns: 175px auto;
              grid-gap: 0 32px;
            }
            .session-cover {
              grid-area: cover;
            }
            .session-details {
              grid-area: details;
            }
            .session-description {
              grid-area: description;
            }
            .session-actions {
              grid-area: actions;
            }

            //////////////////////////////////////////
            // Cover
            //////////////////////////////////////////
            .session-cover {
              cursor: pointer;
              height: 150px;
              @include media-breakpoint-up(xl) {
                height: 232px;
              }
              .cover-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 15px;
              }
            }
            //////////////////////////////////////////
            // Details
            //////////////////////////////////////////
            .session-details {
              .title {
                margin-bottom: 10px;
                font-weight: 600;
                font-size: 20px;
                line-height: 30px;

                .text-muted {
                  color: #949ba5;
                }

                .live {
                  color: var(--primary-store);
                  background: linear-gradient(238.61deg, #01fbff -32.49%, #01bdfa 57.43%);
                  -webkit-background-clip: text;
                  -webkit-text-fill-color: transparent;
                }
              }

              .session-time-date {
                font-size: 14px;
                line-height: 21px;
                @include media-breakpoint-up(xl) {
                  font-weight: 600;
                  font-size: 16px;
                  line-height: 24px;
                }
                .date {
                  display: block;
                  @include media-breakpoint-up(xl) {
                    display: inline-block;
                    margin-right: 18px;
                  }
                  .rtl & {
                    @include media-breakpoint-up(xl) {
                      margin-right: unset;
                      margin-left: 18px;
                    }
                  }
                }

                &.text-muted {
                  color: #949ba5;
                }
              }
            }

            //////////////////////////////////////////
            // Description
            //////////////////////////////////////////
            .session-description {
              .title {
                margin-top: 10px;
                margin-bottom: 0;
                font-weight: 600;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;

                .text-muted {
                  color: #949ba5;
                }
              }

              .description {
                margin-bottom: 0;
                font-size: 14px;
                line-height: 21px;
                color: #5e6978;
              }

              .preview-items {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                padding: 15px 0 0;
                width: 100%;
                @include media-breakpoint-up(xl) {
                  margin: 0;
                  padding: 10px 0;
                }

                .product-image {
                  margin: 0 10px 10px 0;
                  width: 98px;
                  border-radius: 6px;
                  .rtl & {
                    margin: 0 0 10px 10px;
                  }
                }
              }

              .show-more-button {
                display: block;
                margin-top: auto;
                margin-bottom: 15px;
                color: var(--primary-store);
                font-size: 12px;
                line-height: 18px;
                text-decoration-line: underline;

                ::after {
                  display: inline-block;
                  margin-left: 8px;
                  content: '';
                  border-color: var(--primary-store);
                  border-top: 0.4em solid;
                  border-right: 0.35em solid #0000;
                  border-bottom: 0;
                  border-left: 0.35em solid #0000;
                  .rtl & {
                    margin-left: unset;
                    margin-right: 8px;
                  }
                }

                &[aria-expanded='true'] {
                  ::after {
                    display: inline-block;
                    content: '';
                    border-color: var(--primary-store);
                    border-bottom: 0.4em solid;
                    border-right: 0.35em solid #0000;
                    border-top: 0;
                    border-left: 0.35em solid #0000;
                  }
                }
              }

              //////////////////////////////////////////
              // Session feedback
              //////////////////////////////////////////
              .session-feedback {
                margin-top: 10px;
                .feedback-reactions {
                  display: flex;
                  flex-wrap: wrap;

                  .btn-reaction {
                    margin-right: 10px;
                    margin-bottom: 10px;
                    padding-left: 50px;
                    flex: 0 0 150px;
                    color: #fff;
                    font-size: 15px;
                    background-repeat: no-repeat;
                    background-position: 15px center;
                    .rtl & {
                      padding-left: unset;
                      padding-right: 50px;
                    }

                    &.active {
                      box-shadow: 0 0 0 0.2rem var(--primary-store);
                    }

                    &.boring {
                      background-color: #403871;
                      background-image: url('/assets/images/icons/boring-smiley-icon.svg');
                      background-size: 35px auto;
                    }
                    &.frustrating {
                      background-color: #f96544;
                      background-image: url('/assets/images/icons/frustrating-smiley-icon.svg');
                      background-size: 24px auto;
                    }
                    &.terrific {
                      background-color: #31d0aa;
                      background-image: url('/assets/images/icons/terrific-smiley-icon.svg');
                      background-size: 24px auto;
                    }
                    &.fine {
                      background-color: #ef60ab;
                      background-image: url('/assets/images/icons/fine-smiley-icon.svg');
                      background-size: 25px auto;
                    }
                    &:last-child {
                      margin-right: 0;
                      .rtl & {
                        margin-right: unset;
                        margin-left: 0;
                      }
                    }
                  }
                }

                .thanks-for-voting {
                  display: flex;
                  align-items: center;
                  font-weight: 600;
                  font-size: 15px;
                  line-height: 21px;
                  letter-spacing: -0.03em;
                  color: var(--primary-store);

                  .icon {
                    font-size: 20px;
                  }
                }

                .feedback-message {
                  padding: 8px 14px;
                  width: 310px;
                  font-size: 15px;
                  line-height: 21px;
                  border: 0;
                  outline: 0;
                  border-radius: 10px;
                  background-color: #fff;
                  @include media-breakpoint-up(xl) {
                    background-color: #f3f3f3;
                    width: 630px;
                  }
                }
              }
            }

            //////////////////////////////////////////
            // Actions
            //////////////////////////////////////////
            .session-actions {
              align-self: end;

              .btn-send {
                width: 220px;
                @include media-breakpoint-up(xl) {
                  width: 210px;
                }
              }
            }
          }
        }
      }
    }
  }
}
