.checkout-modal {
  .modal-dialog {
    max-height: calc(var(--vh, 1vh) * 80);
    .modal-content {
      app-checkout-modal {
        ng-scrollbar {
          max-height: calc(var(--vh, 1vh) * 80);
        }
        .checkout-warper {
          display: flex;
          .checkout-container {
            flex: 1;

            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-areas:
              'left right'
              'left right'
              'bottom bottom';

            //////////////////////////////////////////
            // Left Side container
            //////////////////////////////////////////
            .left-side-container {
              grid-area: left;
              padding: 0 30px;
              @include media-breakpoint-up(xl) {
                padding: 0 50px 0 30px;
              }
              .rtl & {
                @include media-breakpoint-up(xl) {
                  padding: 0 30px 0 50px;
                }
              }
            }

            //////////////////////////////////////////
            // Pay with
            //////////////////////////////////////////
            .right-side-container {
              grid-area: right;
              display: flex;
              flex-direction: column;
              border-left: 1px solid rgba(#9aa4ad, 0.3);
              @include media-breakpoint-up(xl) {
                padding: 15px 30px 0 50px;
              }
              .rtl & {
                @include media-breakpoint-up(xl) {
                  padding: 15px 50px 0 30px;
                }
              }
              .coriunder-save-card-checkbox {
                margin-top: auto;
                border-top: 1px solid rgba(#9aa4ad, 0.3);
              }
            }

            h3 {
              font-weight: 500;
              font-size: 20px;
              line-height: 24px;

              app-icon {
                margin-right: 8px;
              }
            }

            .borderd-up-title {
              margin-top: 15px;
              border-top: 1px solid rgba(#9aa4ad, 0.3);
              padding-top: 15px;
            }

            ngx-stripe-card-group {
              display: block;
              margin-top: 20px;
              .custom-input {
                margin-bottom: 10px;
              }
            }

            .input-wrapper {
              display: inline-table;
              margin-bottom: 10px;
              width: 100%;
              height: 50px;
              padding: 16px;
              border-radius: 10px;
              border: 0;
              background-color: #f6f7fb;
              &.half {
                width: calc(50% - 5px);
                &.with-margin {
                  margin-left: 10px;
                  .rtl & {
                    margin-left: unset;
                    margin-right: 10px;
                  }
                }
              }
            }

            //////////////////////////////////////////
            // Order checkout
            //////////////////////////////////////////
            .order-checkout-summary {
              display: flex;
              align-items: flex-end;
              padding: 30px 30px 0 30px;
              border-top: 1px solid rgba(#9aa4ad, 0.3);
              grid-area: bottom;

              .order-totals-table {
                flex: 0 0 50%;
                @include media-breakpoint-up(xl) {
                  padding: 0 25px 0 0;
                }

                .rtl & {
                  @include media-breakpoint-up(xl) {
                    padding: 0 0 0 25px;
                  }
                }

                .order-totals-row {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  min-height: 32px;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;

                  &.order-totals-summary {
                    border-top: 1px solid rgba(#9aa4ad, 0.3);
                    font-weight: 700;
                  }

                  &.deposit-row {
                    color: #3bd984;
                  }

                  .value {
                    &.discount {
                      color: var(--secondary-store);
                    }
                  }
                }
              }
              .order-confirm-container {
                @include media-breakpoint-up(xl) {
                  padding: 0 0 0 25px;
                }

                .rtl & {
                  @include media-breakpoint-up(xl) {
                    padding: 0 25px 0 0;
                  }
                }

                .legal-text {
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                }

                .btn-confirm {
                  width: 100%;
                }
              }
            }
            &.show-iframe {
              display: flex;
              flex-direction: column;
              .left-side-container {
              }
              .right-side-container {
                border-left: unset;
                padding: 0 30px;
                border-top: 1px solid rgba(#9aa4ad, 0.3);
                @include media-breakpoint-up(xl) {
                  padding: 15px 50px 0 30px;
                }
                .rtl & {
                  @include media-breakpoint-up(xl) {
                    padding: 15px 30px 0 50px;
                  }
                }
              }
              .order-checkout-summary {
                margin: 30px 0 0 30px;
                padding: 0;

                .order-totals-table {
                  flex: 0 0 100%;
                }
                .order-confirm-container {
                  display: none;
                }
              }
            }
          }
          .checkout-iframe-container {
            border-left: 1px solid rgba(#9aa4ad, 0.3);
            flex: 1;
            iframe {
              border: 0;
              width: 100%;
              height: -webkit-fill-available; //cardcom height
            }
          }
        }
      }
    }
  }
  .bordered-container {
    margin-bottom: 15px;
  }
}
