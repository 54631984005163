.confirmation-modal {
  &.modal {
    &:not(.mobile) {
      .modal-dialog {
        max-width: 100%;
        margin: auto;
        .popup-modal-background {
          .popup-modal {
            width: 22rem;
          }
        }
      }

      .modal-content {
        background-color: unset;
        background: none;
        backdrop-filter: unset;
      }
    }
    &.mobile {
      .modal-dialog {
        display: flex;
        justify-content: center;
      }
      .modal-content {
        width: 93%;
        .close-btn {
          left: 7px;
        }
        .container {
          gap: 30px;
          margin-top: 22px;
          .container-text {
            gap: 15px;
          }
        }
      }
    }
  }
}
